import { BaseApi } from '../BaseApi';
import { createAuthorizedRequest } from '../createAuthorizedRequest';
import { ApiMethod } from '../types';
import { GetVoxTokenContract } from './contracts';

export class VoxTokenApi extends BaseApi {
  protected init() {
    this.getVoxToken = createAuthorizedRequest<GetVoxTokenContract>(
      `${this.baseUrl}get-vox-token`,
      'POST'
    );
  }

  getVoxToken!: ApiMethod<GetVoxTokenContract>;
}
