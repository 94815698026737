import { createDomain } from 'effector';
import { makeRefreshAuth } from '@/store/auth';

export const refreshableApiDomain = createDomain();

refreshableApiDomain.onCreateEffect((event) => {
  event.fail.watch(async (payload) => {
    const { error, params } = payload;
    if (error && typeof error !== 'object') return;
    const { status } = error as Record<string, unknown>;

    if (status === 401) {
      await makeRefreshAuth();
      await event(params);
    }
  });
});
