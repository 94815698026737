const STORAGE_KEY = 'auth-conf';

export const JwtStorage = {
  saveToken(token: string): void {
    localStorage.setItem(STORAGE_KEY, token);
  },
  getToken(): string | null {
    return localStorage.getItem(STORAGE_KEY);
  },
  deleteToken(): void {
    localStorage.removeItem(STORAGE_KEY);
  },
};
