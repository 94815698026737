export const emojiDefault = {
  yes: '✅',
  no: '❌',
  congrats: '🎉',
  // hand: '🖐',
  fire: '🔥',
  like: '❤️',
};

export const emojiHand = '🖐';
