import { priorityByPin, priorityByVad } from '@/store/webrtc/endpoints';
import { Shuffler, InsertStrategyGetter, PriorityGetter } from '@voximplant/shuffler';

enum OrderPriority {
  Pinned = 1,
  Speaker,
  Listener,
}

const shuffle = Shuffler.createShuffle();

const getInsertStrategy: InsertStrategyGetter<string> = (from, to) => {
  // Speakers should be first in non speakers queue if they have ended talk
  if (from === OrderPriority.Speaker && to === OrderPriority.Listener) {
    return 'pushFront';
  }
};

const getPriority: PriorityGetter<string> = (endpoint) => {
  if (priorityByPin.includes(endpoint)) return OrderPriority.Pinned;
  if (priorityByVad.includes(endpoint)) return OrderPriority.Speaker;
  return OrderPriority.Listener;
};

export const reorderEndpoints = (endpoints: string[]): string[] => {
  return shuffle(endpoints, { getPriority, getInsertStrategy });
};
