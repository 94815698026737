import { BaseApi } from '@/api/BaseApi';
import { createAuthorizedRequest } from '@/api/createAuthorizedRequest';
import { ApiMethod } from '@/api/types';
import { ApproveToJoin } from '@/api/conferences/contracts';

export class SpecificConferenceUser extends BaseApi {
  constructor(baseUrl: string, readonly userName: string) {
    super(`${baseUrl}/${userName}`);
  }

  protected init() {
    this.approveToJoin = createAuthorizedRequest<ApproveToJoin>(`${this.baseUrl}/approve`, 'POST');
  }

  approveToJoin!: ApiMethod<ApproveToJoin>;
}
