import { BaseApi } from '../BaseApi';
import { createRequest } from '../createRequest';
import { ApiMethod } from '../types';
import { AuthContract, GuestContract, RefreshContract } from './contracts';

const API_BASE = 'auth';

export class AuthApi extends BaseApi {
  constructor(baseUrl: string) {
    super(`${baseUrl}${API_BASE}`);
  }
  init(): void {
    this.auth = createRequest(this.baseUrl, 'POST', {
      credentials: 'include',
    });
    this.guest = createRequest(`${this.baseUrl}/guest`, 'POST', {
      credentials: 'include',
    });
    this.refresh = createRequest(`${this.baseUrl}/refresh`, 'POST', {
      credentials: 'include',
    });
  }

  auth!: ApiMethod<AuthContract>;
  guest!: ApiMethod<GuestContract>;
  refresh!: ApiMethod<RefreshContract>;
}
