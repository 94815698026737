import { BaseApi } from '@/api/BaseApi';
import { GetInfoContract, JoinContract, RequestToJoinContract } from '@/api/conferences/contracts';
import { createAuthorizedRequest } from '@/api/createAuthorizedRequest';
import { ApiMethod } from '@/api/types';
import { SpecificConferenceUser } from '@/api/conferences/SpecificConferenceUser';

export class SpecificConference extends BaseApi {
  constructor(baseUrl: string, readonly shortUuid: string) {
    super(`${baseUrl}/${shortUuid}`);
  }

  protected init() {
    this.getInfo = createAuthorizedRequest<GetInfoContract>(this.baseUrl);
    this.join = createAuthorizedRequest<JoinContract>(`${this.baseUrl}/join`);
    this.requestToJoin = createAuthorizedRequest<RequestToJoinContract>(
      `${this.baseUrl}/request-join`
    );
  }

  user(userName: string) {
    return new SpecificConferenceUser(this.baseUrl, userName);
  }

  getInfo!: ApiMethod<GetInfoContract>;
  join!: ApiMethod<JoinContract>;
  requestToJoin!: ApiMethod<RequestToJoinContract>;
}
