import { createEffect, createEvent, createStore, EventPayload } from 'effector';
import { AudioDeviceInfo } from '@/store/devices/AudioDeviceInfo';
import { MediaDescription } from '@/store/webrtc/endpointTypes';
import { MirrorTracks } from '@/store/mirrorMedia/MirrorTracks';
import { VideoDeviceInfo } from '@/store/devices/VideoDeviceInfo';

const DEVICE_CHANGE_UPDATE_DELAY = 400;
const MEDIA_BUTTON_DEBOUNCE_GAP = 200;

const $mirrorStore = createStore<MirrorTracks>({});

const toggleMirrorAudioStream = createEvent();
const toggleMirrorVideoStream = createEvent();

const requestMirrorStream = createEvent<{
  selectedAudioDevice?: AudioDeviceInfo;
  selectedVideoDevice?: VideoDeviceInfo;
  sharingTracks?: MediaDescription[];
}>();

const requestMirrorStreamFx = createEffect<
  EventPayload<typeof requestMirrorStream>,
  {
    mirrorStore: MirrorTracks;
    stopped: MirrorTracks;
    sharingTracks: MediaDescription[];
  },
  void
>();

export {
  requestMirrorStream,
  requestMirrorStreamFx,
  toggleMirrorAudioStream,
  toggleMirrorVideoStream,
  $mirrorStore,
  DEVICE_CHANGE_UPDATE_DELAY,
  MEDIA_BUTTON_DEBOUNCE_GAP,
};
