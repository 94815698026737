import { debounce } from '@/helpers/debounce';
import { $devices, getDevices, setActiveDevices } from '@/store/devices/index';
import {
  $mirrorStore,
  DEVICE_CHANGE_UPDATE_DELAY,
  requestMirrorStream,
  toggleMirrorVideoStream,
} from '@/store/mirrorMedia/index';
import { AudioDeviceInfo } from '@/store/devices/AudioDeviceInfo';

const subscribeOnDeviceChange = () => {
  if (navigator.mediaDevices)
    navigator.mediaDevices.ondevicechange = debounce(async () => {
      const listOldAudioDevices = $devices.getState().audioDevices;
      const oldSelectedVideoDevice = $devices.getState().selectedVideoDevice;

      await getDevices();

      const newSelectedVideoDevice = $devices.getState().selectedVideoDevice;
      console.log('mirrorMedia store', 'mediaDevices.ondevicechange video devices', {
        oldSelectedVideoDevice,
        newSelectedVideoDevice,
      });
      const isVideoEnabled = Boolean($mirrorStore.getState().videoPreview);
      if (oldSelectedVideoDevice?.value !== newSelectedVideoDevice?.value && isVideoEnabled) {
        // checking if the selected video device has changed when the video is enabled
        toggleMirrorVideoStream();
      }

      const listNewAudioDevices = $devices.getState().audioDevices;
      console.log(
        'mirrorMedia store',
        'mediaDevices.ondevicechange listNewAudioDevices',
        listNewAudioDevices
      );
      if (listOldAudioDevices && listNewAudioDevices) {
        // find a new audio device
        const newDevice = listNewAudioDevices.filter(
          (newDevice: AudioDeviceInfo) =>
            !listOldAudioDevices.find((oldDevice) => oldDevice.value === newDevice.value)
        );
        console.log('mirrorMedia store', 'mediaDevices.ondevicechange find newDevices', newDevice);
        if (newDevice.length) {
          setActiveDevices({ selectedAudioDevice: newDevice[0] });
          console.log(
            'mirrorMedia store',
            'mediaDevices.ondevicechange set new audio device to status active',
            newDevice[0]
          );
        } // set new audio device to status active
        const { selectedVideoDevice } = $devices.getState();
        requestMirrorStream({
          selectedAudioDevice: newDevice[0],
          ...(isVideoEnabled && { selectedVideoDevice }),
        });
      }
    }, DEVICE_CHANGE_UPDATE_DELAY);
};

const unsubscribeOnDeviceChange = () => {
  if (navigator.mediaDevices) {
    navigator.mediaDevices.ondevicechange = null;
  }
};

export { subscribeOnDeviceChange, unsubscribeOnDeviceChange };
