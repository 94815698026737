import { LogsSender } from './LogsSender';
import { LogCollector } from './LogCollector';
import { $metaInfo } from '@/store/loggers';

/**
 * Manage the schedule of log sending.
 */
export class LogSendingScheduler {
  /**
   * Interval for sending logs in milliseconds.
   * @default 5000ms
   */
  private _interval = 5000;

  /**
   * Id of the setInterval.
   */
  private intervalId: number | undefined;

  constructor(private sender: LogsSender, private collector: LogCollector) {}

  /**
   * Allows to change the defaul value of the sending interval.
   * @param ms Interval value in milliseconds.
   */
  set interval(ms: number) {
    this._interval = ms;
  }

  /**
   * Select the meta information from store.
   * @returns sessionId, meetingId, username.
   */
  private static getMetaInfo() {
    const { sessionId, meetingId, username } = $metaInfo.getState();
    return { sessionId, meetingId, username };
  }

  /**
   * Starts the schedule.
   */
  public startSchedule() {
    this.intervalId = setInterval(() => {
      const { sessionId, meetingId, username } = LogSendingScheduler.getMetaInfo();
      const logs = this.collector.pickUpLogs(sessionId, username, meetingId);
      if (!logs) return;
      this.sender.sendLogs(logs).catch(this.stopSchedule.bind(this));
    }, this._interval);
  }

  /**
   * Stops the schedule.
   */
  public stopSchedule(): void {
    clearInterval(this.intervalId);
  }
}
