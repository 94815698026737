import { $confWSConnected, authStore, updateUserInfo } from '@/store/auth';
import {
  $awaitingApprovalList,
  $users,
  addUsersToApprovalList,
  clearUsers,
  deleteUsersFromApprovalList,
  setRejectedStatus,
  updateMe,
  updateUser,
  updateUsers,
} from '@/store/users';
import { Messaging } from '@voximplant/websdk/modules/messaging';
import { guard, sample } from 'effector';
import { VideoconfApi } from '@/api';
import { ConferenceUser, USER_STATUSES } from '@/api/conferences/structures';
import { addNotification, deleteNotification } from '@/store/modal';
import { changeJoinStatus, meetingStore, StatusOfRequest } from '@/store/meeting';
import { $userDecline } from '@/store/popup';
import { setNotificationState } from '@/store/notification';
import router from '@/router';
import { sortNameAlphabetically } from '@/helpers/sortNameAlphabetically';

updateMe.use(async (user) => {
  console.log('users Store', 'updateMe get user', user);
  const myId = await Messaging.getMyId();
  const me = await Messaging.getUserById(myId);
  const userInfo = {
    chatUser: me.user,
    authUser: {
      authId: user.id,
      avatar: user.picture,
    },
    inCall: true,
    endpointId: 'local',
  };
  console.log('users Store', 'updateMe has user', userInfo);
  return userInfo;
});

updateUsers.use(
  async (meetingId: string): Promise<any> => {
    console.log('updateUsers start');
    const response = await VideoconfApi.conferences.conference(meetingId).getInfo();
    console.log('updateUsers', response);
    return response.data.users;
  }
);

$users
  .on(updateUsers.doneData, (store, conferenceUsers) => {
    console.log('$users', 'updateUsers.doneData get users', conferenceUsers);
    store.users = [...conferenceUsers];
    return { ...store };
  })
  .on(clearUsers, (store) => {
    store.users = [];
    console.log('$users', 'clearUsers', { ...store });
    return { ...store };
  })
  .on(updateMe.doneData, (store, me) => {
    console.log('$users', 'updateMe.doneData get me', me);
    store.me = me;
    return { ...store };
  })
  .on(updateUser, (store, newUserInfo) => {
    const isUserInStore = store.users.find((user) => user.id === newUserInfo.id);
    if (isUserInStore) {
      store.users = store.users.map((user) => {
        return user.username === newUserInfo.username ? newUserInfo : user;
      });
    } else {
      store.users.push(newUserInfo);
    }

    return { ...store };
  })
  .on(setRejectedStatus, (store, vox_user_id) => {
    const rejectedUser = store.users.find((user) => user.vox_user_id === vox_user_id);
    if (rejectedUser) rejectedUser.status = 'REJECTED' as USER_STATUSES;
    return store;
  });

$awaitingApprovalList
  .on(addUsersToApprovalList, (store, newUser) => {
    if (router.currentRoute.value.name !== 'Join') addNotification(newUser);
    return sortNameAlphabetically(newUser ? [...store, newUser] : [...store]);
  })
  .on(deleteUsersFromApprovalList, (store, vox_user_id) => {
    const currentUser = store.find((user) => user.username === $userDecline.getState().userName);
    if (vox_user_id === currentUser?.vox_user_id) setNotificationState('none');
    return store.filter((user) => user.vox_user_id !== vox_user_id);
  });

$users.watch((store) => {
  const usersForApprove = store.users.filter(
    (user: ConferenceUser) => user.status === 'AWAITING_APPROVAL'
  );
  const awaitingList = $awaitingApprovalList.getState();
  usersForApprove.forEach((newUser) => {
    const isUserInList = awaitingList.length && awaitingList.find((user) => user.id === newUser.id);
    if (!isUserInList) {
      addUsersToApprovalList(newUser);
    }
    if (isUserInList && newUser.status !== 'AWAITING_APPROVAL') {
      deleteUsersFromApprovalList(newUser.vox_user_id);
    }
  });
});

sample({
  clock: [updateUsers.doneData, updateUserInfo],
  source: [$users, authStore],
  fn: ([users, authStore]) => {
    const myInfo = users.users.find((user) => user.id === authStore.id);
    return myInfo?.status === 'AWAITING_APPROVAL' // set processing status if login request has already been sent
      ? StatusOfRequest.processing
      : (meetingStore.getState().needRequestToJoin as StatusOfRequest);
  },
  target: changeJoinStatus,
});

sample({
  clock: deleteUsersFromApprovalList,
  target: deleteNotification,
});

const confWSConnectionGuard = guard({
  source: $confWSConnected,
  filter: (isConnected) => isConnected,
});

sample({
  clock: confWSConnectionGuard,
  source: meetingStore,
  // conf backend websocket start opening when meetindId is already meetingStore
  fn: ({ meetingId }) => meetingId as string,
  target: updateUsers,
});
