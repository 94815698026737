import { createEvent, createStore } from 'effector';
import { leaveConference } from '@/store/meeting';
import { $userDecline, resetUserDecline, toggleDeclineAllUser } from '@/store/popup';
import { dismiss, dismissAllWaitingUsers } from '@/store/users';

type NotificationStatuses =
  | 'none'
  | 'reconnecting'
  | 'recording'
  | 'confirmation'
  | 'confirmationAll';

interface Button {
  mode?: any;
  size?: any;
  text: string;
  click: () => void;
}

interface NotificationStatusContent {
  mode: string;
  icon?: string;
  showSpinner?: boolean;
  buttons?: Button[];
}

interface NotificationContentInterface {
  [notificationStatus: string]: NotificationStatusContent;
}

const NotificationContent: NotificationContentInterface = {
  reconnecting: {
    mode: 'error',
    icon: 'ic24-error-fill',
    showSpinner: true,
    buttons: [
      {
        size: 'm',
        mode: 'alert',
        text: 'button',
        click: () => leaveConference(),
      },
    ],
  },
  recording: {
    mode: 'info',
  },
  confirmation: {
    mode: 'info',
    buttons: [
      {
        mode: 'secondary',
        text: 'cancel',
        click: () => resetUserDecline(),
      },
      {
        mode: 'alert',
        text: 'decline',
        click: () => dismiss($userDecline.getState().userName),
      },
    ],
  },
  confirmationAll: {
    mode: 'info',
    buttons: [
      {
        mode: 'secondary',
        text: 'cancel',
        click: () => toggleDeclineAllUser(false),
      },
      {
        mode: 'alert',
        text: 'decline',
        click: () => dismissAllWaitingUsers().finally(() => setNotificationState('none')),
      },
    ],
  },
};

const setNotificationState = createEvent<NotificationStatuses>();
const $notificationState = createStore<NotificationStatuses>('none').on(
  setNotificationState,
  (store, status) => status
);

export { $notificationState, setNotificationState, NotificationContent };
