import appConfig from '@/config';
import { LogCollection } from '@/services/LogCollector';

/**
 * Send logs to the server.
 */
export class LogsSender {
  /**
   * URL for sending logs.
   */
  private static LOG_URL = appConfig.logs.statisticsUrl;

  /**
   * Login for Basic auth to logs service.
   */
  private static LOG_LOGIN = appConfig.logs.statisticsLogin;

  /**
   * Password for Basic auth to logs service.
   */
  private static LOG_PASSWORD = appConfig.logs.statisticsPass;

  /**
   * Generate base64 token for Base Authorisation.
   * @returns token for authorisation.
   */
  private static getBaseAuthToken(): string {
    return btoa(LogsSender.LOG_LOGIN + ':' + LogsSender.LOG_PASSWORD);
  }

  /**
   * Takes the logs from {LogCollector}, stringify them and send to LOG_URL.
   * @param logs {LogCollector}
   * @returns response from remote log service.
   */
  public async sendLogs(logs: LogCollection): Promise<unknown> {
    const result = await fetch(LogsSender.LOG_URL, {
      method: 'POST',
      body: JSON.stringify(logs),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${LogsSender.getBaseAuthToken()}`,
      },
    });

    return result;
  }
}
