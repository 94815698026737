import { createEffect, createEvent, createStore } from 'effector';
import { $chatUsers, joinChat } from '@/store/chat/index';
import { Conference, Events, joinConference } from '@voximplant/websdk/modules/conference';
import { $mirrorStore } from '@/store/mirrorMedia/index';
import router from '@/router';
import { clearAll, endpointEventList } from '@/store/webrtc/endpointManager';
import { registerShortcuts, unRegisterShortcuts } from '@/helpers/shortcuts';
import { $devices } from '@/store/devices';
import { Messenger } from '@/services/Messenger';
import { clearReaction } from '@/store/reactions';
import { VideoconfApi } from '@/api/facade';
import {
  $awaitingApprovalList,
  TIMER_TO_REMOVE_FROM_APPROVAL_LIST,
  updateUsers,
} from '@/store/users';
import { setNotificationState } from '@/store/notification';
import { addNotification, deleteNotification, NOTIFICATION_SHOW_DURATION } from '@/store/modal';
import { refreshableApiDomain } from '@/store/domains';
import { authTroubleEventStatuses } from '@/api/conf-backend-ws/webSocket';
import { openPopup } from '@/store/popup';

interface EndpointMidStore {
  [mid: string]: 'audio' | 'video';
}

interface EndpointAdded {
  endpoint: {
    id: string;
    userName: string;
  };
}

enum StatusOfRequest {
  'needRequest',
  'processing',
  'authorized',
}

interface MeetingDescription {
  meetingId?: string;
  voxMeetingId?: string;
  owner?: boolean;
  messagingId?: string;
  meeting?: Conference;
  needRequestToJoin?: StatusOfRequest;
  isWrongConferenceId?: boolean;
}

enum statusType {
  'none',
  'failed',
  'disconnected',
}

interface MeetingStatus {
  status: statusType;
  reason: string;
}

interface MediaDescription {
  track: MediaStreamTrack;
  kind: 'audio' | 'video';
}

const changeMeetingStatus = createEvent<MeetingStatus>();
const changeJoinStatus = createEvent<StatusOfRequest>();

const createMeeting = refreshableApiDomain.createEffect<void, MeetingDescription, void>(
  async () => {
    const response = await VideoconfApi.conferences.create();

    console.log('meeting store', 'createMeeting server response', response);
    if (response.result) {
      // await joinChat(response.data.messaging_uuid);

      return {
        meetingId: response.data.conference_short_uuid,
        voxMeetingId: response.data.conference_long_uuid,
        messagingId: response.data.messaging_uuid,
        owner: response.data.is_owner,
      };
    }
    console.error('meeting store', 'createMeeting server error', 'ERROR', response.error);
    throw { error: response.error, status: response.status };
  }
);

const startMeeting = createEffect<[string, string], Conference, void>(
  async ([meetingId, voxMeetingId]) => {
    console.log('meeting store', `startMeeting get meetingId ${meetingId}`);
    const media: MediaDescription[] = [];
    const currentTracks = $mirrorStore.getState();
    const mids: EndpointMidStore = {};
    const tracks: { [key: string]: MediaStreamTrack } = {};
    if (!currentTracks.audioPreview) {
      console.error('meeting store', 'startMeeting', 'ERROR', {
        message: 'Dont have a audio',
        currentTracks,
      });
      throw new Error('Dont have a audio');
    } else {
      media.push({ track: currentTracks.audioPreview, kind: 'audio' } as MediaDescription);
      mids['0'] = 'audio';
      tracks['0'] = currentTracks.audioPreview as MediaStreamTrack;
      console.log('meeting store', 'startMeeting with audio track', {
        track: currentTracks.audioPreview,
        kind: 'audio',
      });
    }

    if (currentTracks.videoPreview) {
      media.push({ track: currentTracks.videoPreview, kind: 'video' } as MediaDescription);
      mids['1'] = 'video';
      tracks['1'] = currentTracks.videoPreview as MediaStreamTrack;
      console.log('meeting store', 'startMeeting with video track', {
        track: currentTracks.videoPreview,
        kind: 'video',
      });
    }

    console.log('meeting store', 'startMeeting call joinConference with', {
      number: voxMeetingId,
      sendOptions: {
        media,
      },
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const currentMeeting: Conference = await joinConference({
      number: voxMeetingId,
      sendOptions: {
        media,
      },
    }).catch((err) => {
      console.error('joinConference error', err);
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          console.log('joinConference fallback');
          joinConference({
            number: voxMeetingId,
            sendOptions: {
              media,
            },
          })
            .then(resolve)
            .catch(reject);
        }, 1000); // TODO временное решение, пока не поймем ошибку iceServers undefined
      });
    });
    if (!currentMeeting) {
      throw Error('creating meeting has been failed');
    }
    // TODO: check empty event listener
    currentMeeting.addEventListener(Events.EndpointAdded, (ev: EndpointAdded) => {
      /* updateUserInCall({
        inCall: true,
        userName: ev.endpoint?.userName,
        endpointId: ev.endpoint?.id,
      }); */
    });
    currentMeeting.addEventListener(Events.EndpointRemoved, ({ endpointId }) => {
      console.log('meeting store', 'EndpointRemoved', endpointId);
      const selectedEndpoint = $chatUsers
        .getState()
        .users.find((user) => user.endpointId == endpointId);
      if (selectedEndpoint) {
        /* updateUserInCall({
          inCall: false,
          userName: selectedEndpoint?.chatUser?.userName,
          endpointId: endpointId,
        }); */
      }

      clearReaction(endpointId); // убираем из store реакции этого пользователя для других
    });
    currentMeeting.addEventListener(Events.Disconnected, (ev: any) => {
      if (router.currentRoute.value.name) {
        console.log(
          'meeting store',
          `Disconnected on route ${router.currentRoute.value.name.toString()}`
        );
      }
      if (router.currentRoute.value.name === 'Left') return false; // TODO сделать/починить removeEventListener для Events.Disconnected
      console.error('meeting store', 'meeting Disconnected', ev);
      clearAll();
      router.replace({
        name: 'Left',
      });
      const status = {
        status: statusType.disconnected,
        reason: 'Something went wrong. Try to connect again.',
      };
      return changeMeetingStatus(status);
    });
    /*currentMeeting.addEventListener(Events.Failed, (ev: any) => {
    console.error('meeting store', `meeting Failed with reason ${ev.reason}`);
    router.replace({
      name: 'Left',
    });
    const res = {
      status: statusType.failed,
      reason: 'We were unable to connect to the conference. ' + ev.reason,
    };
    changeMeetingStatus(res);
  });*/
    /*currentMeeting.addEventListener(Events.MessageReceived, ({ message }) => {
    console.log('meeting store', 'MessageReceived', { message });
    if (message.includes('vi/upgrade')) {
      const { url, rand } = JSON.parse(message);
    }
    if (message.includes('sessionId')) {
      const { sessionId } = JSON.parse(message);
      addSessionId(sessionId);
    }
  });*/
    currentMeeting.addEventListener(Events.Connected, () => {
      setTimeout(() => currentMeeting.muteMicrophone(!$devices.getState().audioEnabled), 200);
      console.log('meeting store', 'meeting Connected and router replace to VideoConference');
      router.replace({
        name: 'VideoConference',
        params: {
          conference: meetingId,
        },
      });
      registerShortcuts();
    });
    await currentMeeting
      .start()
      .then(() =>
        console.log('meeting store', 'startMeeting currentMeeting started', currentMeeting)
      )
      .catch((e) => console.error('meeting store', 'startMeeting currentMeeting.start ERROR', e));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.currentMeeting = currentMeeting;
    console.log('meeting store', 'startMeeting return currentMeeting', currentMeeting);
    return currentMeeting;
  }
);

const restoreMeeting = refreshableApiDomain.createEffect<string, MeetingDescription, void>(
  async (meetingId) => {
    console.log('meeting store', `restoreMeeting with id ${meetingId}`);
    const response = await VideoconfApi.conferences.conference(meetingId).join();
    console.log('meeting store', 'restoreMeeting server response', response);

    let needRequestToJoin = StatusOfRequest.authorized;
    if (response.status === 403) {
      needRequestToJoin = StatusOfRequest.needRequest;
    }

    if (response.status === 401) {
      throw { error: response.error, status: response.status };
    }

    const isWrongConferenceId = response.status === 404;

    if (!response.data) {
      console.warn('meeting store', 'restoreMeeting server did not return data ', response.error);
      return {
        meetingId,
        voxMeetingId: '',
        owner: false,
        needRequestToJoin:
          meetingStore.getState().needRequestToJoin || // save the existing flag, required when the user has already requested to join
          needRequestToJoin,
        isWrongConferenceId,
      };
    }

    const meetingInfo: MeetingDescription = {
      meetingId,
      voxMeetingId: response.data.conference_long_uuid,
      messagingId: response.data.messaging_uuid,
      owner: response.data.is_owner,
    };
    console.log('meeting store', 'restoreMeeting return', meetingInfo);
    return meetingInfo;
  }
);

const meetingStore = createStore<MeetingDescription>({});
const $meetingStatus = createStore<MeetingStatus>({ status: statusType.none, reason: '' }).on(
  changeMeetingStatus,
  (state, info) => {
    state.status = info.status;
    state.reason = info.reason;
    console.log('meeting store', `change status to ${info.status} with reason ${info.reason}`);
    return { ...state };
  }
);
const setReconnecting = createEvent<boolean>();
const $reconnecting = createStore(false).on(setReconnecting, (store, status) => {
  setNotificationState('reconnecting');
  return status;
});
$reconnecting.watch(async (status) => {
  if (status) unRegisterShortcuts();
  else {
    // request up-to-date information about the conference after reconnect
    const meetingId = meetingStore.getState().meetingId;
    if (meetingId) await updateUsers(meetingId);
  }
});

meetingStore.on(createMeeting.doneData, (_, payload) => payload);
meetingStore.on(startMeeting.doneData, (store, meeting) => ({
  ...store,
  meeting,
}));
meetingStore.on(restoreMeeting.doneData, (store, payload) => ({ ...store, ...payload }));
meetingStore.on(restoreMeeting.failData, (_, payload) => {
  console.error('meetingStore', 'restoreMeeting.failData', 'ERROR', payload);
});

meetingStore.on(changeJoinStatus, (store, status) => ({
  ...store,
  needRequestToJoin: status,
}));

meetingStore.watch((payload) => {
  console.log('meetingStore', 'watch', payload);
  if (payload.meeting) {
    // add awaiting user notifications on join the meeting and delete after 10 sec
    $awaitingApprovalList.getState().forEach((userInfo) => {
      addNotification(userInfo);
      setTimeout(() => deleteNotification(userInfo.vox_user_id), NOTIFICATION_SHOW_DURATION);
    });
  }
});

startMeeting.doneData.watch((conference) => {
  if (!Messenger.initiliazed) Messenger.init(conference);
  joinChat(meetingStore.getState().messagingId as string);
});

// TODO @irgalamarr
//    fetch(`https://demos05.voximplant.com/cdn/comp?id=av1_${+ new Date()}&ua=${navigator.userAgent}&codec=${JSON.stringify(codecs)}`)
//       .then(response => response.json())
//       .then(data => {
//         document.getElementById('send_res').style.borderColor = "green";
//         console.log(data)
//       });

const leaveConference = () => {
  endpointEventList.clearAll();
  router.replace({
    name: 'Left',
  });
};

let approvalTimeoutHandler: number | undefined;
const startApprovalTimeoutWaiter = createEffect(() => {
  approvalTimeoutHandler = setTimeout(
    () => openPopup('join_approval_timeout'),
    TIMER_TO_REMOVE_FROM_APPROVAL_LIST
  );
});

const stopApprovalTimeoutWaiter = createEffect(() => {
  clearTimeout(approvalTimeoutHandler);
});

const requestJoinMeeting = refreshableApiDomain.createEffect(async (meetingId: string) => {
  await VideoconfApi.conferences
    .conference(meetingId)
    .requestToJoin()
    .then((response) => {
      if (authTroubleEventStatuses.includes(response.status)) {
        throw { error: response.error, status: response.status };
      } // истекло время жизни авторизационного токена
      changeJoinStatus(StatusOfRequest.processing);
      // timer for waiting for join approve - 5 minutes
      startApprovalTimeoutWaiter();
    });
});

export {
  Conference,
  createMeeting,
  meetingStore,
  restoreMeeting,
  startMeeting,
  requestJoinMeeting,
  $meetingStatus,
  statusType,
  changeMeetingStatus,
  setReconnecting,
  $reconnecting,
  StatusOfRequest,
  changeJoinStatus,
  leaveConference,
  startApprovalTimeoutWaiter,
  stopApprovalTimeoutWaiter,
};
