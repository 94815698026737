import { Conference } from '@/store/meeting';
import { Events } from '@voximplant/websdk/modules/conference';
import { MessageEvent } from './events';

type WatchCb = (message: unknown) => unknown;

class Messenger {
  private static deferMessages: string[] = [];
  private static conference: Conference | null = null;
  private static cbs: WatchCb[] = [];

  static get initiliazed(): boolean {
    return Boolean(this.conference);
  }

  static init(conference: Conference) {
    if (this.initiliazed) return;
    this.conference = conference;
    conference.addEventListener(Events.MessageReceived, ({ message }) => {
      this.cbs.forEach((cb) => cb(JSON.parse(message)));
    });
    this.sendDeferMessages();
  }

  static send(message: unknown): void {
    const jsonStr = JSON.stringify(message);
    if (this.conference) {
      this.conference.sendMessage(jsonStr);
    } else {
      this.deferMessages.push(jsonStr);
    }
  }

  static watch(cb: WatchCb) {
    this.cbs.push(cb);
  }

  static isMessageEvent = (message: unknown): message is MessageEvent['payload'] =>
    Boolean(
      typeof message === 'object' &&
        message !== null &&
        'command' in message &&
        typeof (message as { command: string }).command === 'string'
    );

  private static sendDeferMessages() {
    if (this.deferMessages.length && this.conference) {
      this.deferMessages.forEach((message) => this.conference?.sendMessage(message));
    }
  }
}

export { Messenger };
export * from './events';
