import * as Bowser from 'bowser';
import { useIsMobilePlatform } from '@/hooks/isMobile';

let userAgent: Vendor;
let browserVersion: string;

function detectVendor(): Vendor {
  const browser = Bowser.getParser(window.navigator.userAgent);

  if (browser.getPlatformType() === 'mobile') userAgent = Vendor.mobile;
  else if (browser.getPlatformType() === 'tablet') userAgent = Vendor.tablet;
  else if (browser.getPlatformType() === 'desktop' && browser.getBrowserName() === 'Safari')
    userAgent = Vendor.safari;
  else if (browser.getPlatformType() === 'desktop' && browser.getBrowserName() === 'Chrome')
    userAgent = Vendor.chrome;
  else if (browser.getPlatformType() === 'desktop' && browser.getBrowserName() === 'Firefox')
    userAgent = Vendor.firefox;
  else userAgent = Vendor.browser;
  console.log('vendor helper', 'detectVendor return', Vendor[userAgent]);
  detectBrowserVersion();
  return userAgent;
}

function detectBrowserVersion(): string {
  const browser = Bowser.getParser(window.navigator.userAgent);
  browserVersion = browser.getBrowserVersion();
  console.log('vendor helper', 'browserVersion', browserVersion);
  return browser.getBrowserVersion();
}

export enum Vendor {
  'browser',
  'safari',
  'chrome',
  'firefox',
  'mobile',
  'tablet',
}

const canScreen = (): boolean => {
  const { isMobilePlatform } = useIsMobilePlatform();
  console.log('vendor helper', 'canScreen', !isMobilePlatform);
  return !isMobilePlatform;
};

export { canScreen, detectVendor, userAgent, browserVersion };
