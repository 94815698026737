import { bindConsole, VILogger } from '@voximplant/vi-logger';
import { LogCollector } from '@/services/LogCollector';
import { LogSendingScheduler } from '@/services/LogSendingScheduler';
import { LogsSender } from '@/services/LogsSender';

const myLogger = (): VILogger => {
  const collector = new LogCollector();
  const sender = new LogsSender();
  const scheduler = new LogSendingScheduler(sender, collector);

  scheduler.startSchedule();

  return bindConsole(window, {
    prefix: '[videoconf-frontend]',
    muted: false,
    timestampOrigin: Date.now,
    interceptor: ({ level, messages, index, timestamp }) => {
      const sendLevels = ['warn', 'error', 'log'];
      if (!sendLevels.includes(level)) return;

      const isStatsMessage = messages.find(
        (message) => typeof message === 'string' && message.includes('Collected stats')
      );
      if (isStatsMessage) return; // filter statistic messages

      const stringMessages: string[] = [];
      if (messages.length > 2) {
        // converted the videoconf logs to parse format
        messages.forEach((message, index) => {
          // store or file name
          if (index === 0) stringMessages.push(`[${message}]`);
          // function name and purpose
          else if (index === 1) stringMessages.push(`[${message}]`);
          // data conversion to string
          else if (typeof message !== 'string') stringMessages.push(JSON.stringify(message));
          // default adding
          else stringMessages.push(message);
        });
      } else {
        // add others logs
        messages.forEach((message) => {
          if (typeof message !== 'string') stringMessages.push(JSON.stringify(message));
          else stringMessages.push(message);
        });
      }

      stringMessages.forEach((message) =>
        collector.collectLog(`${index.toString()} ${message}`, level, timestamp)
      );
    },
  });
};

export { myLogger };
