import { createEvent, createStore } from 'effector';
import { setNotificationState } from '@/store/notification';

type PopupSection =
  | 'device-permission'
  | 'invite'
  | 'sharing-permission'
  | 'recording'
  | 'join_approval_rejected'
  | 'join_approval_timeout'
  | 'token-not-valid'
  | 'join-token-not-valid'
  | 'auth-failed'
  | 'connection-was-lost';
interface PopupStore {
  opened: boolean;
  section: PopupSection;
}

interface DeclineUser {
  userName: string;
  displayName: string;
}

const $popup = createStore<PopupStore>({ opened: false, section: 'device-permission' });
const openPopup = createEvent<PopupSection | undefined>();
const closePopup = createEvent();

const setUserDecline = createEvent<DeclineUser>();
const resetUserDecline = createEvent();

const toggleDeclineAllUser = createEvent<boolean>();
const $declineAllUser = createStore(false).on(toggleDeclineAllUser, (store, payload) => {
  if (payload) setNotificationState('confirmationAll');
  else setNotificationState('none');
  return payload;
});
const $userDecline = createStore<DeclineUser>({
  userName: '',
  displayName: '',
})
  .on(setUserDecline, (store, payload) => {
    if (!payload) return store;
    store.userName = payload?.userName;
    store.displayName = payload?.displayName;
    setNotificationState('confirmation');
    return store;
  })
  .on(resetUserDecline, () => {
    setNotificationState('none');
    return {
      userName: '',
      displayName: '',
    };
  });

$popup
  .on(openPopup, (state, section) => {
    console.log('popup Store', 'openPopup', { opened: true, section: section || state.section });
    return { opened: true, section: section || state.section };
  })
  .on(closePopup, (state) => {
    console.log('popup Store', 'closePopup', { ...state, opened: false });
    return { ...state, opened: false };
  });

export {
  $popup,
  openPopup,
  closePopup,
  $userDecline,
  setUserDecline,
  resetUserDecline,
  $declineAllUser,
  toggleDeclineAllUser,
};
