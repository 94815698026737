/**
 * Auth module
 */
import { combine, createEffect, createEvent, createStore } from 'effector';
import { User } from '@/api/users/structures';
import { openPopup } from '@/store/popup';
import router from '@/router';
import { refreshableApiDomain } from '@/store/domains';

enum AuthState {
  NoAuth,
  OAuth,
  Processing,
}
interface UserInfo {
  id: number;
  email: string;
  displayName: string;
  voxUserId: number;
  username: string;
  picture: string;
}

interface AuthStore extends Partial<UserInfo> {
  authState: AuthState;
  sdkConnected: boolean;
}

type AuthError = { error: string; status: number };

const GUEST_EMAIL_DOMAIN = 'guestimplant.com';
const GUEST_SESSION_STORAGE_KEY = 'authorized-guest-username';

const resetAuthStore = createEvent();
const authStore = createStore<AuthStore>({
  authState: AuthState.NoAuth,
  sdkConnected: false,
});
const $isAuthorized = authStore.map((state) => state.authState === AuthState.OAuth);

const toggleTokenNotValid = createEvent<boolean>();
const $isTokenNotValid = createStore(false).on(toggleTokenNotValid, (store, status) => {
  if (status && router.currentRoute.value.name === 'Join') openPopup('join-token-not-valid');
  else if (status) openPopup('token-not-valid');
  return status;
});

const $isGuest = authStore.map((state) => Boolean(state.email?.includes(GUEST_EMAIL_DOMAIN)));
const $currentGuestSession = createStore<string | null>(
  sessionStorage.getItem(GUEST_SESSION_STORAGE_KEY) || null
);
const $isAuthorizedGuestSession = combine(
  authStore,
  $currentGuestSession,
  (auth, currentGuestSession) => auth.username === currentGuestSession
);
const setCurrentGuestSession = createEvent<string>();

const updateState = createEvent<AuthState>();
const updateUserInfo = createEvent<User>();

const initSDK = createEffect<void, void>();
const authSDK = refreshableApiDomain.createEffect<string, void>('authSDK');

const restoreAuth = refreshableApiDomain.createEffect<
  void,
  { status: number; jwt: string | null; user: User },
  AuthError
>();
const makeAuth = createEffect<
  { code: string; redirectUri: string },
  { jwt: string; user: User },
  AuthError
>();
const makeGuestAuth = createEffect<string, { jwt: string; user: User }, AuthError>();
const makeRefreshAuth = createEffect<
  void,
  { jwt: string; user: User },
  { error: string; status: number }
>();

const changeMyDisplayName = refreshableApiDomain.createEffect<
  [username: string, displayName: string],
  { user: User }
>();

const authorizeGuestSession = createEffect<string, string>();

const $confWSConnected = createStore(false);
const setConfWSConnectionState = createEvent<boolean>();

const openWebSocketConnectionFx = createEffect<[jwt: string, meetingId: string], void>();

export {
  AuthError,
  GUEST_SESSION_STORAGE_KEY,
  authStore,
  $isAuthorized,
  $isGuest,
  $currentGuestSession,
  $isAuthorizedGuestSession,
  $isTokenNotValid,
  toggleTokenNotValid,
  AuthState,
  resetAuthStore,
  restoreAuth,
  makeAuth,
  makeGuestAuth,
  makeRefreshAuth,
  authSDK,
  initSDK,
  updateState,
  updateUserInfo,
  setCurrentGuestSession,
  changeMyDisplayName,
  authorizeGuestSession,
  $confWSConnected,
  setConfWSConnectionState,
  openWebSocketConnectionFx,
};
