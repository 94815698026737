import { ConferencesApi } from '@/api/conferences';
import appConfig from '../config';
import { AuthApi } from './auth';
import { UsersApi } from './users';
import { VoxTokenApi } from './vox-token';

export const VideoconfApi = {
  auth: new AuthApi(appConfig.baseServerUrl),
  voxToken: new VoxTokenApi(appConfig.baseServerUrl),
  users: new UsersApi(appConfig.baseServerUrl),
  conferences: new ConferencesApi(appConfig.baseServerUrl),
};
