import { createAuthorizedRequest } from '../createAuthorizedRequest';
import { BaseApi } from '../BaseApi';
import { ApiMethod } from '../types';
import { MeContract } from './contracts';
import { SpecificUser } from '@/api/users/SpecificUser';

const API_BASE = 'users';

export class UsersApi extends BaseApi {
  constructor(baseUrl: string) {
    super(`${baseUrl}${API_BASE}`);
  }

  protected init() {
    this.me = createAuthorizedRequest<MeContract>(`${this.baseUrl}/me`, 'GET');
  }

  user(username: string) {
    return new SpecificUser(this.baseUrl, username);
  }

  me!: ApiMethod<MeContract>;
}
