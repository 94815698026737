import { createEvent, createStore, createEffect } from 'effector';
// import { createEffect } from 'effector/effector.cjs';

type RecordingState = 'recording' | 'none';

const startRecording = createEvent<string>();
const requestStartRecording = createEffect<void, void>();
const requestStopRecording = createEffect<void, void>();
const getRecordingStatus = createEffect<void, void>();
const stopRecording = createEvent();
const setRecordStatus = createEvent<RecordingState>();
const $recording = createStore<string | boolean>(false);
const $recordStatus = createStore<RecordingState>('none');

export {
  $recording,
  $recordStatus,
  startRecording,
  setRecordStatus,
  stopRecording,
  requestStartRecording,
  requestStopRecording,
  getRecordingStatus,
};
