import { reconnectWs } from '@/api/conf-backend-ws/reconnectWs';

let havePong = false;
let needReconnect: number;
const TIMER_FOR_SEND_PING = 5000;
const CALL_RECONNECT_TIMER = 10000;
const PING_STRING = JSON.stringify({ event: 'PING' });
const sendPing = (socket: WebSocket): void => {
  if (!socket) return;
  if (socket.readyState !== 1) return;
  // check availability of "Pong" to avoid duplicate reconnect Ws
  if (havePong) {
    socket.send(PING_STRING);
    console.log(`[ConfBackendWS] Send ${PING_STRING}`);
    setTimeout(() => sendPing(socket), TIMER_FOR_SEND_PING);
    needReconnect = setTimeout(() => reconnectWs(socket), CALL_RECONNECT_TIMER);
    setHavePong(false);
  }
};

const setHavePong = (status: boolean): void => {
  havePong = status;
};

export { needReconnect, sendPing, setHavePong };
