import { createEvent, createStore } from 'effector';
import { v4 as uuid } from 'uuid';

interface MetaInfo {
  sessionId: string;
  meetingId?: string;
  username?: string;
}

const $metaInfo = createStore<MetaInfo>({
  sessionId: uuid(),
});

const initMetaInfo = createEvent<Record<string, string>>();

export { $metaInfo, initMetaInfo };
