import { BaseApi } from '@/api/BaseApi';
import { createAuthorizedRequest } from '@/api/createAuthorizedRequest';
import { ApiMethod } from '@/api/types';
import { UserEditContract, UserGetInfoContract } from '@/api/users/contracts';

export class SpecificUser extends BaseApi {
  constructor(baseUrl: string, readonly userName: string) {
    super(`${baseUrl}/${userName}`);
  }

  protected init() {
    this.getInfo = createAuthorizedRequest<UserGetInfoContract>(this.baseUrl, 'GET');
    this.edit = createAuthorizedRequest<UserEditContract>(`${this.baseUrl}/edit`);
  }

  getInfo!: ApiMethod<UserGetInfoContract>;
  edit!: ApiMethod<UserEditContract>;
}
