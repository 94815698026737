import { createEvent, createStore } from 'effector';
import { ConferenceUser } from '@/api/conferences/structures';
import router from '@/router';

interface InvitePeopleModal {
  title: string;
  number: string;
  activeCountry: Record<string, unknown>;
  countries: Record<string, unknown>[];
}

interface SettingsModal {
  initTab?: string;
}

interface ModalStoreInterface {
  opened: boolean;
  component?: string;
  componentOptions?: InvitePeopleModal | SettingsModal;
}

const openModal = createEvent<ModalStoreInterface>();
const closeModal = createEvent<void>();

const NOTIFICATION_SHOW_DURATION = 30000;
const addNotification = createEvent<ConferenceUser>();
const deleteNotification = createEvent<number>();
const $notificationStack = createStore<ConferenceUser[]>([])
  .on(addNotification, (store, payload) => {
    if (router.currentRoute.value.name !== 'Join') {
      // do not close the notification until the user join in meeting
      setTimeout(() => deleteNotification(payload.vox_user_id), NOTIFICATION_SHOW_DURATION);
    }
    // скрываем первое уведомление, если их более 5
    if (store.length >= 5) deleteNotification(store[0].vox_user_id);
    return payload ? [...store, payload] : [...store];
  })
  .on(deleteNotification, (store, vox_user_id) => {
    return store.filter((user) => user.vox_user_id !== vox_user_id);
  });

const isModalOpened = createStore<ModalStoreInterface>({ opened: false, component: '' })
  .on(openModal, (state, content) => {
    console.log('isModalOpened store', 'openModal', content);
    return content;
  })
  .on(closeModal, (state) => {
    console.log('isModalOpened store', 'closeModal', { ...state, opened: false });
    return { ...state, opened: false };
  });

export {
  isModalOpened,
  openModal,
  closeModal,
  $notificationStack,
  addNotification,
  deleteNotification,
  NOTIFICATION_SHOW_DURATION,
};
