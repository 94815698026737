import { JwtStorage } from '@/api';
import { meetingStore } from '@/store/meeting';
import { openWebSocketConnection } from '@/api/conf-backend-ws/webSocket';
import { getDateTimeFormat } from '@/helpers/getDateTimeFormat';
import { openPopup } from '@/store/popup';

let reconnectTimeout: number;
let breakReconnectTimeout: number;
const CONNECTION_ATTEMPT_TIMER = 3000;
const RECONNECT_TIMER = 30000;
const reconnectWs = (socket: WebSocket): void => {
  if (reconnectTimeout === 0) return; // abort reconnection to WS after timeout
  if (!breakReconnectTimeout) {
    breakReconnectTimeout = setTimeout(() => {
      console.error(`[ConfBackendWS] connection not re-established at ${getDateTimeFormat()}`);
      clearTimeout(reconnectTimeout);
      reconnectTimeout = 0;
      openPopup('connection-was-lost');
    }, RECONNECT_TIMER);
  }

  const jwt = JwtStorage.getToken() as string;
  const meetingId = meetingStore.getState().meetingId;
  if (meetingId) {
    socket?.close(); // WS return 1006 code
    console.log('[ConfBackendWS] old socket closed');
    openWebSocketConnection(jwt, meetingId).addEventListener('open', () => {
      clearTimeout(reconnectTimeout);
      reconnectTimeout = 0;
    });
  }
  reconnectTimeout = setTimeout(() => reconnectWs(socket), CONNECTION_ATTEMPT_TIMER);
};

export { reconnectWs, reconnectTimeout, breakReconnectTimeout };
