import * as Bowser from 'bowser';

enum Platform {
  'apple',
  'other',
}
let userPlatform: Platform;

function detectPlatform(): Platform {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const platform = browser.getPlatform();

  if (platform.type === 'desktop' && platform.vendor === 'Apple') userPlatform = Platform.apple;
  else userPlatform = Platform.other;
  console.log('platform helper', 'detectPlatform', userPlatform);
  return userPlatform;
}

export { detectPlatform, userPlatform, Platform };
