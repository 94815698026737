const appConfig = {
  baseUrl: new URL(location.href).origin + process.env.VUE_APP_PUBLIC_PATH,
  voxAppDomain: process.env.VUE_APP_VOX_APP_DOMAIN as string,
  baseServerUrl: `https://${process.env.VUE_APP_BASE_SERVER_URL}` as string,
  baseWebSocketUrl: `wss://${process.env.VUE_APP_BASE_SERVER_URL}` as string,
  baseAuthRedirectUrl: process.env.VUE_APP_BASE_AUTH_REDIRECT_URL as string,
  nodeEnv: process.env.NODE_ENV as string,
  environment: process.env.VUE_APP_BASE_SERVER_URL?.includes('dev') ? 'dev' : 'prod',
  // Google App Client ID
  clientId: process.env.VUE_APP_CLIENT_ID as string,
  logs: {
    statisticsUrl: process.env.VUE_APP_STATISTICS_URL as string,
    statisticsLogin: process.env.VUE_APP_STATISTICS_LOGIN as string,
    statisticsPass: process.env.VUE_APP_STATISTICS_PASS as string,
  },
  sdkServerPool: process.env.VUE_APP_SDK_SERVER_POOL
    ? process.env.VUE_APP_SDK_SERVER_POOL.split(';')
    : null,
  guestAccount: {
    displayName: process.env.VUE_APP_GUEST_DISPLAY_NAME as string,
    login: process.env.VUE_APP_GUEST_LOGIN as string,
    password: process.env.VUE_APP_GUEST_PASSWORD as string,
  },
};

export default appConfig;
