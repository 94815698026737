import { createEvent, createStore } from 'effector';
import { emojiDefault, emojiHand } from '@/store/emojiList';
import { $chatUsers, sendMessageToAll } from '@/store/chat/index';

const updateReaction = createEvent<ReactionEvent>();
const sendReaction = createEvent<LocalReactionEvent>();
const clearReaction = createEvent<string>();
const toggleHand = createEvent();
const clearLocalReactions = createEvent();

interface LocalReactionEvent {
  type: string;
  active: boolean;
}

interface ReactionEvent extends LocalReactionEvent {
  userName: string;
}

const createLocalReactions = (emojilist: {
  [type: string]: string;
}): { [type: string]: boolean } => {
  const local: { [type: string]: boolean } = {};
  Object.keys(emojilist).forEach((type: string) => {
    local[type] = false;
  });
  console.log('reactions Store', 'createLocalReactions', local);
  return local;
};

const reactionsStore = createStore<{
  emojilist: { [type: string]: string };
  reactions: { [userName: string]: Set<string> };
  handRaise: boolean;
  localReactions: { [type: string]: boolean };
}>({
  emojilist: emojiDefault,
  reactions: {},
  handRaise: false,
  localReactions: createLocalReactions(emojiDefault),
})
  .on(updateReaction, (store, event) => {
    console.log('reactionsStore', 'updateReaction get', { event, store });
    if (!store.reactions[event.userName]) store.reactions[event.userName] = new Set();
    if (event.active) {
      store.reactions[event.userName].add(event.type);
    } else {
      store.reactions[event.userName].delete(event.type);
    }
    console.log('reactionsStore', 'updateReaction', { ...store });
    return { ...store };
  })
  .on(clearReaction, (store, endpointId: string) => {
    const user = $chatUsers.getState().users.find((user) => user.endpointId === endpointId);
    if (user) store.reactions[user.chatUser.userName]?.clear();
    console.log('reactionsStore', 'clearReaction', { ...store });
    return { ...store };
  })
  .on(clearLocalReactions, (store) => {
    const newStore = store;
    newStore.reactions = {};
    newStore.localReactions = {};
    console.log('reactionsStore', 'clearLocalReactions', { ...newStore });
    return { ...newStore };
  })
  .on(sendReaction, (store, { type, active }) => {
    console.log('reactionsStore', 'sendReaction get', { type, active });
    store.localReactions[type] = active;
    sendMessageToAll({
      text: `${active ? store.emojilist[type] : ''}`,
      customData: [{ command: 'reaction', type, active }],
    });
    console.log('reactionsStore', 'sendReaction return', { ...store });
    return { ...store };
  })
  .on(toggleHand, (store) => {
    store.handRaise = !store.handRaise;
    sendMessageToAll({
      text: `${store.handRaise ? emojiHand : ''}`,
      customData: [{ command: 'reaction', type: 'hand', active: store.handRaise }],
    });
    return { ...store };
  });

export {
  reactionsStore,
  updateReaction,
  sendReaction,
  clearReaction,
  clearLocalReactions,
  toggleHand,
};
