import appConfig from '@/config';

export interface LogCollection {
  platform: string;
  environment: string;
  sessionId?: string;
  meetingId?: string;
  username?: string;
  type: string;
  logs: LogMessage[];
}

interface LogMessage {
  date: number;
  tag?: string;
  level: string;
  log: string;
}

type LogLevel = 'error' | 'warn' | 'log';

/**
 * Allows to collect and accumulate logs in one place
 * and then pick them up with subsequent cleaning the log storage.
 */
export class LogCollector {
  /**
   * The Log storage / collection.
   */
  private logs: LogMessage[] = [];

  /**
   * Define the platform.
   */
  private static PLATFORM = 'web';

  /**
   * Define the running environment.
   */
  private static ENVIRONMENT = appConfig.environment;

  /**
   * The constant for tag key. Identify the project linked with the log.
   */
  private static LOG_TAG = 'videoconf';

  /**
   * Define the type of the message.
   */
  private static TYPE = 'logs';

  /**
   * The map for log levels between the app and log service.
   */
  private static LOG_LEVEL_MAP = {
    warn: 'warning',
    log: 'info',
    error: 'error',
  };

  /**
   * Accumulate logs in the log collection.
   * @param level level of the logging.
   * @param message message of the log.
   * @param timestamp time of the message.
   */
  public collectLog(log: string, level: string, timestamp = Date.now()): void {
    this.logs.push({
      date: timestamp,
      tag: LogCollector.LOG_TAG,
      level: LogCollector.LOG_LEVEL_MAP[level as LogLevel],
      log,
    });
  }

  /**
   * Cleans the log collection up.
   */
  private resetLogCollection(): void {
    this.logs = [];
  }

  /**
   * Picks logs up and eraises the collection.
   * @returns {LogCollection}
   */
  public pickUpLogs(
    sessionId: string,
    username?: string,
    meetingId?: string
  ): LogCollection | null {
    if (!this.logs.length) return null;

    const logs = [...this.logs];
    this.resetLogCollection();

    return {
      platform: LogCollector.PLATFORM,
      environment: LogCollector.ENVIRONMENT,
      sessionId,
      meetingId,
      username,
      type: LogCollector.TYPE,
      logs,
    };
  }
}
