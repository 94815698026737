import { createRequest } from './createRequest';
import { JwtStorage } from './JwtStorage';
import { ApiContract, RequestFunction } from './types';

export const createAuthorizedRequest = <T extends ApiContract>(
  url: string,
  method = 'POST',
  jwt?: string
): RequestFunction<T['params'], T['response']> => {
  if (jwt) {
    JwtStorage.saveToken(jwt);
  }

  const makeRequest = createRequest<T['params'], T['response']>(url, method);

  return (body?, headers?) => {
    const token = jwt || JwtStorage.getToken();

    if (!token) {
      throw Error('A token has not found in storage');
    }

    const authHeaders: Record<string, string> = {
      authorization: `Bearer ${token}`,
      ...headers,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return makeRequest(body, authHeaders);
  };
};
