import { BaseApi } from '@/api/BaseApi';
import { SpecificConference } from '@/api/conferences/SpecificConference';
import { createAuthorizedRequest } from '@/api/createAuthorizedRequest';
import { ApiMethod } from '@/api/types';
import { CreateContract } from './contracts';

const API_BASE = 'conferences';

export class ConferencesApi extends BaseApi {
  constructor(baseUrl: string) {
    super(`${baseUrl}${API_BASE}`);
  }

  protected init() {
    this.create = createAuthorizedRequest<CreateContract>(`${this.baseUrl}/create`, 'POST');
  }

  create!: ApiMethod<CreateContract>;

  conference(shortUuid: string) {
    return new SpecificConference(this.baseUrl, shortUuid);
  }
}
