import { guard, sample } from 'effector';
import { authStore } from '@/store/auth';
import { $metaInfo } from '@/store/loggers';
import { meetingStore } from '@/store/meeting';

const usernameGuard = guard({
  clock: authStore,
  source: [authStore, $metaInfo],
  filter: ([{ username: newUsername }, { username }]) => newUsername !== username,
});

const usernameSample = sample({
  source: usernameGuard,
  fn: ([{ username }]) => username as string,
});

const meetingIdGuard = guard({
  clock: meetingStore,
  source: [meetingStore, $metaInfo],
  filter: ([{ meetingId: newMeetingId }, { meetingId }]) => newMeetingId !== meetingId,
});

const meetingIdSample = sample({
  source: meetingIdGuard,
  fn: ([{ meetingId }]) => meetingId,
});

$metaInfo
  .on(usernameSample, (store, username) => ({
    ...store,
    username,
  }))
  .on(meetingIdSample, (store, meetingId) => ({
    ...store,
    meetingId,
  }));
