import { createEvent } from 'effector';
import {
  $endpointManager,
  $endpoints,
  $sharing,
  toggleSharing,
  updateEndpoints,
} from '@/store/webrtc/endpoints';
import { createEffect } from 'effector/compat';
import { $devices } from '@/store/devices/index';
import { requestMirrorStream, $mirrorStore } from '@/store/mirrorMedia/index';
import { meetingStore } from '@/store/meeting';
import { MediaDescription } from '@/store/webrtc/endpointTypes';
import { userAgent, Vendor } from '@/helpers/vendor';
import { clearChat } from '@/store/chat/index';
import { SharingType } from '@/store/sharing/index';
import { clearUsers } from '@/store/users';
import { closeDrawer } from '@/store/drawer';
import { clearLocalReactions } from '@/store/reactions';
import { unRegisterShortcuts } from '@/helpers/shortcuts';
import { requestStopRecording } from '@/store/recording';
import { unsubscribeOnDeviceChange } from '@/store/devices/onDeviceChange';

const removeEndpoint = createEvent<string>();
const updateLocalEndpoint = createEvent<{
  from: MediaDescription | null;
  to: MediaDescription | null;
}>();
const removeSharing = createEvent();
const clearAll = createEvent();

const addSharing = createEffect<
  SharingType,
  { type: SharingType; tracks: MediaDescription[] },
  void
>(async (type: SharingType) => {
  console.log('webrtc store', 'addSharing get type', type);
  let captureStream: MediaStream;
  const audio = type === 'withVideoAndAudio' && userAgent === Vendor.chrome;
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    captureStream = await navigator.mediaDevices.getDisplayMedia({ audio, video: true });
    console.log('webrtc store', 'addSharing captureStream all tracks', {
      videoTracks: captureStream.getVideoTracks(),
      audioTrack: captureStream.getAudioTracks(),
    });
    const videoTrack = captureStream.getVideoTracks()[0];
    const audioTrack = captureStream.getAudioTracks()[0];
    const tracks: MediaDescription[] = [{ track: videoTrack, kind: 'screen_video' }];
    console.log('webrtc store', 'addSharing add video track', {
      track: videoTrack,
      kind: 'screen_video',
    });
    if (audio && audioTrack) {
      tracks.push({ track: audioTrack, kind: 'screen_audio' });
      console.log('webrtc store', 'addSharing add audio track', {
        track: audioTrack,
        kind: 'screen_audio',
      });
    }
    toggleSharing({ type, tracks });
    videoTrack.onended = () => {
      removeSharing();
    };
    console.log('webrtc store', 'addSharing return', { type, tracks });
    return { type, tracks };
  } catch (e) {
    console.error('webrtc store', 'addSharing catch', 'ERROR', e);
    throw new Error(e);
  }
});

$endpoints
  .on(addSharing.doneData, (store, { type, tracks }) => {
    const meeting = meetingStore.getState().meeting;
    if (!meeting) {
      console.log('$endpoints', 'ERROR addSharing.doneData Cannot find current meeting');
      throw new Error('Cannot find current meeting');
    }
    console.log('$endpoints', `addSharing.doneData get type ${type}`);
    if (type === 'replace') {
      const localVideo = $mirrorStore.getState().videoPreview;
      const sharingVideo = tracks.find((track) => track.kind === 'screen_video');
      localVideo?.stop();
      if (sharingVideo) {
        updateLocalEndpoint({
          from: localVideo ? { track: localVideo, kind: 'video' } : null,
          to: sharingVideo,
        });

        if (localVideo) {
          console.log(
            '$endpoints',
            'addSharing.doneData meeting.replaceMedia with track',
            localVideo
          );
          meeting.replaceMedia({ track: localVideo, kind: 'video' }, sharingVideo);
        } else {
          console.log('$endpoints', 'addSharing.doneData meeting.addMedia with track', [
            sharingVideo,
          ]);
          meeting.addMedia([sharingVideo]);
        }
      }
    } else if (type === 'withVideo' || type == 'withVideoAndAudio') {
      console.log('$endpoints', 'addSharing meeting.addMedia withVideo or AndAudio', tracks);
      meeting.addMedia(tracks);
    }
    console.log('$endpoints', 'addSharing.doneData new store', store);
  })
  .on(removeSharing, () => {
    const typeOfSharing = $sharing.getState().typeOfSharing;
    console.log('$endpoints', 'removeSharing current typeOfSharing', typeOfSharing);
    if (typeOfSharing === 'withVideo' || typeOfSharing === 'withVideoAndAudio') {
      const tracks = $sharing.getState().tracks;
      console.log('$endpoints', 'removeSharing(withVideo) current sharing tracks', tracks);
      if (tracks.length) meetingStore.getState().meeting?.removeMedia(tracks);
      toggleSharing({ type: 'none', tracks: [] });
    } else if (typeOfSharing === 'replace' && $mirrorStore.getState().videoPreview) {
      requestMirrorStream({
        selectedAudioDevice: $devices.getState().selectedAudioDevice,
        selectedVideoDevice: $devices.getState().selectedVideoDevice,
        sharingTracks: $sharing.getState().tracks || [],
      });
      toggleSharing({ type: 'none', tracks: [] });
    } else if (typeOfSharing === 'replace' && !$mirrorStore.getState().videoPreview) {
      const tracks = $sharing.getState().tracks;
      console.log('$endpoints', 'removeSharing(replace) current sharing tracks', tracks);
      if (tracks.length) meetingStore.getState().meeting?.removeMedia(tracks);
      toggleSharing({ type: 'none', tracks: [] });
      updateLocalEndpoint({
        from: tracks[0],
        to: null,
      });
    }
  })
  .on(clearAll, () => {
    console.log('$endpoints', 'clearAll old $endpoints store', $endpoints.getState());
    $mirrorStore.getState().videoPreview?.stop();
    $mirrorStore.getState().audioPreview?.stop();
    $sharing.getState().tracks.forEach(({ track }) => track.stop());
    if ($sharing.getState().isSharing) toggleSharing({ type: 'none', tracks: [] });
    closeDrawer();
    clearLocalReactions();
    clearChat();
    clearUsers();
    unRegisterShortcuts();
    requestStopRecording();
    unsubscribeOnDeviceChange();
    console.log('$endpoints', 'clearAll $endpoints store after clear', $endpoints.getState());
    return {
      media: [],
      endpoints: [],
    };
  });

$endpointManager.on(updateEndpoints, (store, payload) => payload);

const endpointEventList = {
  removeEndpoint,
  addSharing,
  removeSharing,
  updateLocalEndpoint,
  clearAll,
};

export { endpointEventList, clearAll };
