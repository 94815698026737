import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { SpaceUIPlugin } from '@voximplant/spaceui';
import '@voximplant/spaceui/common/сommon.css';
import '@voximplant/spaceui/dist/spaceui.css';
import { createI18n } from 'vue-i18n';
import en from '@/locales/en.json';
import { getDateTimeFormat } from '@/helpers/getDateTimeFormat';
import { version } from '@/plugins/version';
import { runLocalStorageMigrations } from '@/helpers/localStorage';

const messages = {
  en: en,
  es: {
    signIn: {
      title: 'Iniciar sesión',
    },
  },
};
const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});

const spaceUiPlugin = SpaceUIPlugin({
  spriteUrl: require(`@voximplant/spaceui/common/sui-icons.svg`),
});

runLocalStorageMigrations();

const app = createApp(App).use(i18n).use(router).use(spaceUiPlugin).use(version);

app.config.errorHandler = console.error;
app.config.warnHandler = console.warn;

app.mount('#app');

console.log(`application is init at ${getDateTimeFormat()}`);
