import { toggleMirrorAudioStream, toggleMirrorVideoStream } from '@/store/mirrorMedia';
import { toggleHand } from '@/store/reactions';
import { $drawer, closeDrawer, openDrawer } from '@/store/drawer';
import { changeLayoutType } from '@/store/layout';
import { LayoutTypeMap } from '@/helpers/layouts';
import { Platform, userPlatform } from '@/helpers/platform';
import { $devices, toggleAudioEvent } from '@/store/devices';
import { localMute } from '@/store/conferenceEvents';

interface Shortcut {
  title: string;
  key: string;
  code: string[];
  name: string;
}

interface Shortcuts {
  [shortcutName: string]: Shortcut;
}

interface ShortcutFunctions {
  [shortcutName: string]: () => void;
}

const layoutTypes = Object.keys(LayoutTypeMap) as Array<keyof typeof LayoutTypeMap>;
let layoutNameIndex = 0;

const shortcutsFunction: ShortcutFunctions = {
  microphone: () => {
    toggleAudioEvent();
    toggleMirrorAudioStream();
    localMute(!$devices.getState().audioEnabled);
  },
  camera: () => toggleMirrorVideoStream(),
  hand: () => toggleHand(),
  chat: () => {
    if ($drawer.getState().opened) closeDrawer();
    else openDrawer({ section: 'chat' });
  },
  participants: () => {
    if ($drawer.getState().opened) closeDrawer();
    else openDrawer({ section: 'contactList' });
  },
  nextLayout: () => {
    // when index overflow reset it for go to the beginning of the circle
    if (layoutNameIndex >= layoutTypes.length - 1) layoutNameIndex = 0;
    else layoutNameIndex++;

    const type = layoutTypes[layoutNameIndex];
    changeLayoutType(LayoutTypeMap[type]);
  },
  previousLayout: () => {
    // when the index is already at the beginning of the circle, set the last value
    if (!layoutNameIndex) layoutNameIndex = layoutTypes.length - 1;
    else layoutNameIndex--;

    const type = layoutTypes[layoutNameIndex];
    changeLayoutType(LayoutTypeMap[type]);
  },
};

const getShortcuts = (isMac: boolean): Shortcuts => {
  return {
    microphone: {
      title: 'Toggle microphone',
      key: isMac ? '⌘ + d' : 'ctrl + d',
      code: isMac ? ['Meta', 'KeyD'] : ['Control', 'KeyD'],
      name: 'microphone',
    },
    camera: {
      title: 'Toggle camera',
      key: isMac ? '⌘ + e' : 'ctrl + e',
      code: isMac ? ['Meta', 'KeyE'] : ['Control', 'KeyE'],
      name: 'camera',
    },
    hand: {
      title: 'Raise/Lower hand',
      key: isMac ? 'ctrl + ⌘ + h' : 'ctrl + Alt + h',
      code: isMac ? ['Control', 'Meta', 'KeyH'] : ['Control', 'altKey', 'KeyH'],
      name: 'hand',
    },
    chat: {
      title: 'Toggle chat window',
      key: isMac ? 'ctrl + ⌘ + c' : 'ctrl + Alt + c',
      code: isMac ? ['Control', 'Meta', 'KeyC'] : ['Control', 'altKey', 'KeyC'],
      name: 'chat',
    },
    participants: {
      title: 'Toggle participants window',
      key: isMac ? 'ctrl + ⌘ + p' : 'ctrl + Alt + p',
      code: isMac ? ['Control', 'Meta', 'KeyP'] : ['Control', 'altKey', 'KeyP'],
      name: 'participants',
    },
    nextLayout: {
      title: 'Next layout',
      key: isMac ? 'ctrl + ⌘ + j' : 'ctrl + Alt + j',
      code: isMac ? ['Control', 'Meta', 'KeyJ'] : ['Control', 'altKey', 'KeyJ'],
      name: 'nextLayout',
    },
    previousLayout: {
      title: 'Previous layout',
      key: isMac ? 'ctrl + ⌘ + k' : 'ctrl + Alt + k',
      code: isMac ? ['Control', 'Meta', 'KeyK'] : ['Control', 'altKey', 'KeyK'],
      name: 'previousLayout',
    },
  };
};

const bindShortcuts = (event: KeyboardEvent) => {
  const shortcuts = getShortcuts(userPlatform === Platform.apple);
  Object.values(shortcuts).forEach((shortcut) => {
    // collect the condition for keys of the shortcut
    let isMeta = true;
    let isControl = true;
    let isKeyMatch = false;
    shortcut.code.forEach((code) => {
      if (code === 'Meta') isMeta = event.metaKey;
      else if (code === 'altKey') isControl = event.altKey;
      else if (code === 'Control') isControl = event.ctrlKey;
      else if (code.includes('Key')) isKeyMatch = code === event.code;
    });
    const isShortcutActive = isMeta && isControl && isKeyMatch;

    if (isShortcutActive) {
      shortcutsFunction[shortcut.name]();
      event.preventDefault();
      event.stopPropagation();
    }
  });
};

const registerShortcuts = (): void => {
  document.addEventListener('keydown', bindShortcuts);
  console.log('shortcuts helper', 'shortcuts is registered');
};

const unRegisterShortcuts = (): void => {
  document.removeEventListener('keydown', bindShortcuts);
  console.log('shortcuts helper', 'shortcuts is removed');
};

export { getShortcuts, registerShortcuts, unRegisterShortcuts };
