import { onInit } from './migrations/initLocalStorage';
const storedLocalStorageVersion = 'conf-storage-version';

export const runLocalStorageMigrations = (): void => {
  const migrations = [onInit];
  const initialVersion = localStorage.getItem(storedLocalStorageVersion);

  for (let i = 0; i < migrations.length; i++) {
    const currentVersion = localStorage.getItem(storedLocalStorageVersion) ?? 0;
    const migration = migrations.find((migration) => migration.from === Number(currentVersion));
    if (migration) {
      console.log(
        `[LocalStorage] Migrate local storage from v${migration.from} to v${migration.to}`
      );
      migration.run();
      localStorage.setItem(storedLocalStorageVersion, String(migration.to));
    }
  }

  const lastVersion = localStorage.getItem(storedLocalStorageVersion);
  if (initialVersion !== lastVersion) window.location.reload();
};
