export default {
  install(): void {
    const appVersion = process.env.VUE_APP_VERSION?.trim();
    const gitCommit = process.env.VUE_APP_COMMIT_HASH?.trim() ?? 'unknown commit';
    const gitBranch = process.env.VUE_APP_BRANCH?.trim() ?? 'unknown branch';
    const buildDate = process.env.VUE_APP_BUILD_DATE?.trim();

    if (appVersion) console.log(`Application version: ${appVersion} (${gitBranch}:${gitCommit})`);
    if (buildDate) console.log(`Build at: ${buildDate}`);
  },
};
