import { createEvent, createStore, restore } from 'effector';
import { VoxTilerInput } from '@voximplant/tiler';
import { EndpointStore, EndpointMediaStore } from '@/store/webrtc/endpointTypes';
import { $sharing, toggleSharing } from '@/store/sharing/index';
import { RenderVideoStore } from '@/store/endpointMedia/RenderVideoStore';
import { throttle } from '@/helpers/throttle';

export interface SocketPosition {
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface media {
  audio?: EndpointMediaStore;
  video?: EndpointMediaStore;
}

export interface CompiledEndpointsMedia extends VoxTilerInput {
  mid: media;
  muted: boolean;
  canPlay: boolean;
}

interface RawOutput extends SocketPosition {
  stream: CompiledEndpointsMedia;
}

const $endpoints = createStore<EndpointStore>({
  media: [],
  endpoints: [],
});
// store contains active and overflowed endpoints
const $endpointManager = createStore<RenderVideoStore[]>([]);

const updateEndpoints = createEvent<RenderVideoStore[] | undefined>();
const watchEndpoint = createEvent<void>();

const $localEndpointTile = $endpointManager.map((endpoint: any) => {
  return endpoint.find((element: any) => element.stream.id === 'local');
});

let priorityByPin: string[] = [];
let priorityByVad: string[] = [];

const throttledWatchEndpoint = throttle(() => watchEndpoint(), 1000);

const setPriorityByPin = (endpoints: string[]): void => {
  priorityByPin = endpoints;
};

const changePriorityByPin = (endpoints: string[]): void => {
  setPriorityByPin(endpoints);
  watchEndpoint();
};
const changePriorityByVad = (endpoints: string[]): void => {
  priorityByVad = endpoints;
  throttledWatchEndpoint();
};

/*
const $hasEndpointSharing = $endpoints.map(() => {
  /!*console.log('$hasEndpointSharing', '$hasEndpointSharing map get media', media);
  const isScreenVideo = media.some((endpoint) => endpoint.kind === 'screen_video');
  console.log('$hasEndpointSharing', 'map return', isScreenVideo);
  return isScreenVideo;*!/
  return false;
});
*/
const toggleHasEndpointSharing = createEvent<boolean>();
const $hasEndpointSharing = restore(toggleHasEndpointSharing, false);

export {
  RawOutput,
  $endpoints,
  $endpointManager,
  watchEndpoint,
  updateEndpoints,
  $sharing,
  $hasEndpointSharing,
  toggleSharing,
  toggleHasEndpointSharing,
  priorityByPin,
  priorityByVad,
  setPriorityByPin,
  changePriorityByPin,
  changePriorityByVad,
  $localEndpointTile,
};
