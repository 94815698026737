import { Messaging } from '@voximplant/websdk/modules/messaging';
import appConfig from '@/config';

export const sendDirectMessage = async (
  userName: string, // собеседник, которого добавляют
  displayName: string, // отображаемое имя моего пользователя
  isApproved: boolean,
  conferenceShortUuid: string | undefined
): Promise<void> => {
  return await Messaging.getUser(`${userName}@${appConfig.voxAppDomain}`).then((resp) => {
    Messaging.createConversation({
      customData: {},
      uberConversation: false,
      enablePublicJoin: false,
      direct: true,
      participants: [
        {
          userId: resp.user.userId,
        },
      ],
    }).then((conversation) => {
      // message format approved with mobile development team
      const text = {
        event: 'CONFERENCE/REQUEST_TO_JOIN',
        user: displayName,
        isApproved,
        conferenceShortUuid,
      };
      conversation.sendMessage(JSON.stringify(text));
    });
  });
};
