import { auth, SDK } from '@voximplant/websdk';
import { ConsoleLogger } from '@voximplant/websdk/modules/logger';
import { MessagingLoader } from '@voximplant/websdk/modules/messaging';
import { WebrtcLoader } from '@voximplant/websdk/modules/webrtc';
import { ConferenceLoader } from '@voximplant/websdk/modules/conference';
import { StatisticsLoader } from '@voximplant/websdk/modules/statistics';
import { guard, sample } from 'effector';
import {
  $confWSConnected,
  $isAuthorizedGuestSession,
  $isGuest,
  authorizeGuestSession,
  authSDK,
  AuthState,
  authStore,
  changeMyDisplayName,
  GUEST_SESSION_STORAGE_KEY,
  initSDK,
  makeAuth,
  makeGuestAuth,
  makeRefreshAuth,
  openWebSocketConnectionFx,
  resetAuthStore,
  restoreAuth,
  setConfWSConnectionState,
  updateState,
  updateUserInfo,
} from '.';
import { JwtStorage, VideoconfApi } from '@/api';
import appConfig from '@/config';
import { openWebSocketConnection } from '@/api/conf-backend-ws/webSocket';
import { $currentGuestSession, setCurrentGuestSession } from '@/store/auth';
import { createMeeting, meetingStore, restoreMeeting } from '@/store/meeting';
import { stringifyError } from '@/helpers/stringifyError';
import { retrySdkLogin } from '@/store/auth/retrySdkLogin';
import { getDateTimeFormat } from '@/helpers/getDateTimeFormat';
import { $popup, closePopup, openPopup } from '@/store/popup';
import router from '@/router';
import { asyncDebounce } from '@/helpers/debounce';
let RECONNECT_SDK_TIMEOUT_ID: ReturnType<typeof setTimeout>;

authStore
  .on(updateState, (store, authState) => {
    console.log('authStore', 'updateState', AuthState[authState]);
    return { ...store, authState };
  })
  .on(updateUserInfo, (store, response) => {
    console.log('authStore', 'updateUserInfo', response);
    const userInfo = {
      authState: AuthState.OAuth,
      id: response.id,
      displayName: response.display_name,
      email: response.email,
      picture: response.picture,
      username: response.username,
      voxUserId: response.vox_user_id,
    };

    return {
      ...store,
      ...userInfo,
    };
  })
  .on(resetAuthStore, () => ({
    authState: AuthState.NoAuth,
    sdkConnected: false,
  }))
  .on(authSDK.doneData, (store) => {
    clearTimeout(RECONNECT_SDK_TIMEOUT_ID);
    return {
      ...store,
      sdkConnected: true,
    };
  });

restoreAuth.use(async () => {
  try {
    const response = await VideoconfApi.users.me();
    console.log('backend response', 'restoreAuth data', JSON.stringify(response));
    if (response.error) {
      console.error('auth Store', `restoreAuth NoAuth ERROR: ${response.error}`);
      throw { error: response.error, status: response.status };
    }
    console.log('auth store', 'restoreauth return', JSON.stringify(response));
    return {
      status: response.status,
      // user: {} as typeof response.data,
      user: response.data,
      jwt: JwtStorage.getToken(),
    };
  } catch (err) {
    console.error(
      'backend jwt',
      'restoreauth error',
      'ERROR Local storage is empty',
      JwtStorage.getToken()
    );
    throw err;
  }
});

makeAuth.use(async ({ code, redirectUri: redirectUrl }) => {
  const response = await VideoconfApi.auth.auth({ code, redirectUrl });
  console.log('backend response', 'makeAuth get response', response);
  if (response.error) {
    console.error('backend', 'makeAuth Not auth', 'ERROR', response);
    throw { error: response.error, status: response.status };
  }

  return {
    jwt: response.data.jwt,
    user: response.data.user,
  };
});

const REFRESH_DEBOUNCE_DELAY = 10000; // 10s
const debouncedRefresh = asyncDebounce(VideoconfApi.auth.refresh, REFRESH_DEBOUNCE_DELAY);

makeRefreshAuth.use(async () => {
  const response = await debouncedRefresh();

  console.log('backend response', 'makeRefreshAuth get response', response);
  if (response.error) {
    console.error('backend', 'makeRefreshAuth Not refreshed', 'ERROR', response);
    throw { error: response.error, status: response.status };
  }

  return {
    jwt: response.data.jwt,
    user: response.data.user,
  };
});

makeRefreshAuth.failData.watch(() => {
  JwtStorage.deleteToken();
  resetAuthStore();
  router.replace({
    name: 'Signin',
  });
});

makeGuestAuth.use(async (displayName) => {
  const response = await VideoconfApi.auth.guest({ displayName });

  return {
    jwt: response.data.jwt,
    user: response.data.user,
  };
});

sample({
  clock: [makeAuth.pending, makeGuestAuth.pending, restoreAuth.pending],
  source: authStore,
  fn: (store, isAuthProcessing) => {
    if (isAuthProcessing) {
      return AuthState.Processing;
    }

    return store.username ? AuthState.OAuth : AuthState.NoAuth;
  },
  target: updateState,
});

sample({
  clock: [
    makeAuth.doneData,
    makeGuestAuth.doneData,
    restoreAuth.doneData,
    changeMyDisplayName.doneData,
  ],
  fn: ({ user }) => user,
  target: updateUserInfo,
});

const guestSessionGuard = guard({
  clock: restoreAuth.doneData,
  source: [$isGuest, $isAuthorizedGuestSession],
  filter: ([isGuest, isAuthorizedGuestSession]) => !isGuest || isAuthorizedGuestSession,
});

const restoreAuthSample = sample({
  clock: guestSessionGuard,
  source: authStore,
  fn: (user) => user.username as string,
});

const makeAuthSample = sample({
  clock: [makeAuth.doneData, makeGuestAuth.doneData],
  fn: ({ user }) => user.username,
});

const isSdkAuthInProgress = authSDK.pending.map((x) => !x);

guard({
  clock: [restoreAuthSample, makeAuthSample, authorizeGuestSession],
  filter: isSdkAuthInProgress, // needed to prevent double call authSDK (first creation conf)
  target: authSDK,
});

sample({
  clock: [makeAuth.doneData, makeGuestAuth.doneData, makeRefreshAuth.doneData],
  fn: ({ jwt }) => {
    JwtStorage.saveToken(jwt);
  },
});

const openConfWSGuard = guard({
  clock: [restoreMeeting.doneData, createMeeting.doneData],
  source: [meetingStore, $confWSConnected, openWebSocketConnectionFx.pending],
  filter: ([{ meetingId }, wsConnected, isOpeningConfWs]) => {
    const jwt = JwtStorage.getToken();
    console.log(
      `openConfWSGuard: meetingId ${meetingId}, wsConnected: ${wsConnected}, isOpeningConfWs: ${isOpeningConfWs} and token is ${jwt}`
    );
    return Boolean(jwt && !wsConnected && !isOpeningConfWs && meetingId);
  },
});

sample({
  clock: openConfWSGuard,
  fn: ([{ meetingId }]) => {
    const jwt = JwtStorage.getToken() as string;
    return [jwt, meetingId as string] as [string, string];
  },
  target: openWebSocketConnectionFx,
});

sample({
  clock: makeGuestAuth.doneData,
  fn: ({ user }) => {
    return user.username;
  },
  target: setCurrentGuestSession,
});

sample({
  clock: setCurrentGuestSession,
  fn: (username) => {
    sessionStorage.setItem(GUEST_SESSION_STORAGE_KEY, username);
  },
});

initSDK.use(async () => {
  const config = {
    connection: {
      servers: appConfig.sdkServerPool,
    },
    logger: new ConsoleLogger({ logLevel: 'trace' }),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    modules: [StatisticsLoader(), MessagingLoader(), WebrtcLoader(), ConferenceLoader()],
  };
  console.log('auth Store', 'authSDK call SDK.configure with config', config);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const configure = await SDK.configure(config);
  const connect = await SDK.connect();
  console.log('auth Store', 'SDK configure and connect', { configure, connect });
});

authSDK.use(async () => {
  SDK.clear();
  await initSDK();

  // TODO: Need to refactor authStore events and effects - for some reason authSDK call param can be undefined
  //  on restore auth
  const store = authStore.getState();
  const username = store.username;

  console.log('Starting authSDK. Current store: ', JSON.stringify(store));
  if (!username) {
    console.error(
      'Failed to start authSDK - no username provided. AuthStore:',
      JSON.stringify(store)
    );
    throw new Error('Failed to start authSDK - no username provided');
  }

  const authMethod = new auth.OtpAuth({
    username: `${username}@${appConfig.voxAppDomain}`,
    onOneTimeLoginKey: async (oneTimeKey) => {
      const response = await VideoconfApi.voxToken.getVoxToken({ oneTimeKey });

      console.log('backend response', 'authSDK return data', response);
      return response.data?.ott || '0';
    },
    options: {},
  });
  console.log('auth Store', 'authSDK call SDK.login with', authMethod);

  try {
    const authResult = await SDK.login(authMethod);
    console.log('auth Store', 'authSDK login', JSON.stringify(authResult));
  } catch (err) {
    console.error(`SDK auth error at ${getDateTimeFormat()}`, stringifyError(err));
    // if (errorHandler!) throw errorHandler;
    await retrySdkLogin(authMethod);
  }
});

$currentGuestSession.on(setCurrentGuestSession, (_, username) => username);

authorizeGuestSession.use((username: string) => username);

guard({
  clock: authorizeGuestSession.doneData,
  filter: (username) => Boolean(username),
  target: setCurrentGuestSession,
});

changeMyDisplayName.use(async ([username, displayName]) => {
  const { data, result, error, status } = await VideoconfApi.users
    .user(username)
    .edit({ displayName });
  if (!result) {
    console.error(`Changing name has failed: ${error}`);
    throw { error: error, code: status };
  }
  return { user: data };
});

authSDK.failData.watch((err) => {
  console.error('auth sdk error', stringifyError(err));
  openPopup('auth-failed');
  RECONNECT_SDK_TIMEOUT_ID = setTimeout(authSDK, 5000);
});

restoreAuth.failData.watch((err) => {
  console.error('restore auth error', stringifyError(err));
});

makeAuth.failData.watch((err) => {
  console.error('make auth error', stringifyError(err));
});

sample({
  clock: resetAuthStore,
  fn: () => {
    SDK.clear();
  },
});

guard({
  clock: authSDK.doneData,
  filter: () => $popup.getState().section === 'auth-failed',
  target: closePopup,
});

openWebSocketConnectionFx.use((args) => {
  openWebSocketConnection(...args);
});

$confWSConnected.on(setConfWSConnectionState, (_, state) => state);
