import appConfig from '@/config';
import { authStore, makeRefreshAuth, setConfWSConnectionState } from '@/store/auth';
import { openPopup } from '@/store/popup';
import {
  $awaitingApprovalList,
  addUsersToApprovalList,
  deleteUsersFromApprovalList,
  setRejectedStatus,
  updateUser,
} from '@/store/users';
import {
  changeJoinStatus,
  meetingStore,
  restoreMeeting,
  startMeeting,
  StatusOfRequest,
  stopApprovalTimeoutWaiter,
} from '@/store/meeting';
import { needReconnect, sendPing, setHavePong } from '@/api/conf-backend-ws/sendPing';
import {
  breakReconnectTimeout,
  reconnectTimeout,
  reconnectWs,
} from '@/api/conf-backend-ws/reconnectWs';
import { addNotification } from '@/store/modal';

export const authTroubleEventStatuses = [
  401, // Authentication token invalid
  10401, // Client public key not found or expired. Please, auth again
  403, // Authentication token not provided
];

const openWebSocketConnection = (jwt: string, conferenceShortUuid: string): WebSocket => {
  console.log(
    `[ConfBackendWS] Start opening websocket with following params: ${jwt} and ${conferenceShortUuid}`
  );
  const socket = new WebSocket(
    `${appConfig.baseWebSocketUrl}websocket?authorization=${jwt}&conference-short-uuid=${conferenceShortUuid}`
  );

  socket.onmessage = async function (event) {
    console.log(`[ConfBackendWS] Has received event ${event.data}`);
    const message = JSON.parse(event.data);
    const eventName = message.event;
    const eventData = message.payload;
    const eventStatus = eventData?.status;

    // Auth trouble handler
    if (authTroubleEventStatuses.includes(eventStatus)) {
      try {
        const { jwt } = await makeRefreshAuth();
        if (jwt) openWebSocketConnection(jwt, conferenceShortUuid);
      } catch (error) {
        console.error('Websocket', 'ERROR restoreAuth catch', jwt, error);
      }

      // System events handlers
    } else if (eventName === 'SYSTEM/INTERNAL') {
      console.error(`[ConfBackendWS] error ${eventData}`);
    } else if (eventName === 'SYSTEM/OK') {
      console.log('[ConfBackendWS] connected');
      setHavePong(true);
      sendPing(socket);
      setConfWSConnectionState(true);
      clearTimeout(breakReconnectTimeout);
    } else if (eventName === 'PONG') {
      clearTimeout(needReconnect);
      setHavePong(true);
    }

    // проверка на то, что событие пришло по текущей конференции
    if (
      eventName !== 'PONG' && // PONG event don't have eventData
      eventData.conference_short_uuid === meetingStore.getState().meetingId
    ) {
      // Эвенты конференции
      if (eventName === 'CONFERENCE/USER_AWAITING_APPROVAL') {
        // Пользователь ожидает допуска к конференции
        const awaitingList = $awaitingApprovalList.getState();
        const isUserInList =
          awaitingList.length && awaitingList.find((user) => user.id === eventData.user.id);
        // Если пользователя уже нет в комнате ожидания, добавляем его в список
        if (!isUserInList) addUsersToApprovalList(eventData.user);
        else addNotification(eventData.user);
      } else if (eventName === 'CONFERENCE/ACTIVE_STATE_CHANGED') {
        // Пользователь зашел/вышел из конференции
        updateUser(eventData.user);
      }
      // ⚠ Event can occur many times
      else if (eventName === 'CONFERENCE/APPROVED') {
        await stopApprovalTimeoutWaiter();
        // Пользователь допущен до конференции
        if (
          eventData.approved_user.id === authStore.getState().id &&
          meetingStore.getState().needRequestToJoin !== StatusOfRequest.authorized
        ) {
          // присоединиться к конференции, если это тот пользователь которого впустили
          changeJoinStatus(StatusOfRequest.authorized);
          try {
            await restoreMeeting(eventData.conference_short_uuid);
          } catch (e) {
            if (e.error) console.log('[ConfBackendWS] restoreMeeting filed with response ', e);
          }
          await startMeeting([
            eventData.conference_short_uuid as string,
            eventData.conference_long_uuid as string,
          ]);
        }
        const userInList = $awaitingApprovalList
          .getState()
          .find((user) => user.id === eventData.approved_user.id);
        if (userInList) {
          // удалить одобренного пользователя из комнаты ожидания
          deleteUsersFromApprovalList(userInList?.vox_user_id);
        }
      }
      // ⚠ Event can occur many times
      else if (eventName === 'CONFERENCE/REJECTED') {
        // Пользователь не допущен до конференции
        await stopApprovalTimeoutWaiter();
        if (eventData.rejected_user.id === authStore.getState().id) {
          // Если rejected_user имеет авторизационный ID пользователя, показать уведомление
          openPopup('join_approval_rejected');
        }
        // удалить отклоненного пользователя из комнаты ожидания
        deleteUsersFromApprovalList(eventData.rejected_user.vox_user_id);
        // установить статус REJECTED для юзера в users store
        setRejectedStatus(eventData.rejected_user.vox_user_id);
      }
    }
  };
  socket.onerror = (err) => {
    console.error(
      `[ConfBackendWS] Error has occured ${JSON.stringify(err, Object.getOwnPropertyNames(err))}`
    );
    if (!reconnectTimeout) reconnectWs(socket);
  };
  socket.onclose = ({ code, wasClean, reason }) => {
    console.warn(
      `[ConfBackendWS] Websocket has closed: code ${code}, wasClean ${wasClean} and reason ${reason}`
    );
    if (!reconnectTimeout && (code === 1012 || code === 1000 || !wasClean)) {
      reconnectWs(socket);
    }
  };

  return socket;
};

export { openWebSocketConnection };
