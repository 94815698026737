import { createEvent, restore } from 'effector';
import { CanvasDef, gridBuilder, Layout, LayoutType, LayoutTypeMap } from '@/helpers/layouts';
import { priorityByPin } from '@/store/webrtc/endpoints';

const resizeVideoSlot = createEvent<CanvasDef>();

const $canvas = restore(resizeVideoSlot, { width: 0, height: 0 });

const changeLayoutType = createEvent<LayoutType>();

const $layoutType = restore(changeLayoutType, LayoutTypeMap.grid);

const getLayout = (): Layout =>
  gridBuilder({
    ...$canvas.getState(),
    userSelectedGridName: $layoutType.getState(),
    // temporary consider that pinned users equal sharing endpoints
    hasSharing: Boolean(priorityByPin.length),
  });

export {
  // stores
  $canvas,
  $layoutType,
  getLayout,
  // events
  resizeVideoSlot,
  changeLayoutType,
};
