import { createEvent, createStore } from 'effector';
import { ConferenceUser } from '@/api/conferences/structures';
import { ChatUser } from '@/store/chat';
import { VideoconfApi } from '@/api';
import { sendDirectMessage } from '@/helpers/sendDirectMessage';
import { resetUserDecline } from '@/store/popup';
import { meetingStore } from '@/store/meeting';
import { authStore } from '@/store/auth';
import { authTroubleEventStatuses } from '@/api/conf-backend-ws/webSocket';
import { sortNameAlphabetically } from '@/helpers/sortNameAlphabetically';
import { refreshableApiDomain } from '@/store/domains';

interface UsersStore {
  me?: ChatUser;
  users: ConferenceUser[];
}

const TIMER_TO_REMOVE_FROM_APPROVAL_LIST = 300000; // general agreement of web and mobile teams - 5 minutes
const $awaitingApprovalList = createStore<ConferenceUser[]>([]);
const addUsersToApprovalList = createEvent<ConferenceUser>();
const deleteUsersFromApprovalList = createEvent<number>();

const $users = createStore<UsersStore>({
  me: void 0,
  users: [],
});
const $usersInCall = $users.map((store) =>
  sortNameAlphabetically(store.users.filter((user) => user?.is_active))
);

const notActiveStatuses = ['AWAITING_APPROVAL', 'REJECTED'];
const $usersOffline = $users.map((store) =>
  sortNameAlphabetically(
    store.users.filter((user) => !user?.is_active && !notActiveStatuses.includes(user.status))
  )
);

const clearUsers = createEvent();

const updateMe = refreshableApiDomain.createEffect<
  { id: number; picture: string },
  ChatUser,
  void
>();

const updateUsers = refreshableApiDomain.createEffect<string, any[], void>();

const updateUser = refreshableApiDomain.createEvent<ConferenceUser>();
const setRejectedStatus = createEvent<number>();

const dismiss = refreshableApiDomain.createEffect(async (username: string) => {
  const meetingId = meetingStore.getState().meetingId;
  const displayName = authStore.getState().displayName;

  if (meetingId && username) {
    return await VideoconfApi.conferences
      .conference(meetingId)
      .user(username)
      .approveToJoin({ isApproved: false })
      .then((response) => {
        if (authTroubleEventStatuses.includes(response.status)) {
          throw { error: response.error, status: response.status };
        }
        if (displayName) {
          sendDirectMessage(username, displayName, false, meetingStore.getState().meetingId);
        }
        deleteUsersFromApprovalList(response.data?.vox_user_id);
        resetUserDecline();
      });
  }
});

const approve = refreshableApiDomain.createEffect(async (username: string) => {
  const meetingId = meetingStore.getState().meetingId;
  const displayName = authStore.getState().displayName;
  if (meetingId && username) {
    return await VideoconfApi.conferences
      .conference(meetingId)
      .user(username)
      .approveToJoin({ isApproved: true })
      .then((response) => {
        if (authTroubleEventStatuses.includes(response.status)) {
          throw { error: response.error, status: response.status };
        }
        if (displayName) {
          sendDirectMessage(username, displayName, true, meetingStore.getState().meetingId);
        }
        deleteUsersFromApprovalList(response.data?.vox_user_id);
        resetUserDecline();
      });
  }
});

const dismissAllWaitingUsers = refreshableApiDomain.createEffect(
  async (): Promise<void> => {
    const users = $awaitingApprovalList.getState();
    for (const user of users) {
      await dismiss(user.username);
    }
  }
);

const approveAllWaitingUsers = refreshableApiDomain.createEffect(
  async (): Promise<void> => {
    const users = $awaitingApprovalList.getState();
    for (const user of users) {
      await approve(user.username);
    }
  }
);

export {
  approve,
  dismiss,
  UsersStore,
  $users,
  $usersInCall,
  $usersOffline,
  setRejectedStatus,
  updateUser,
  updateUsers,
  updateMe,
  clearUsers,
  $awaitingApprovalList,
  addUsersToApprovalList,
  deleteUsersFromApprovalList,
  TIMER_TO_REMOVE_FROM_APPROVAL_LIST,
  dismissAllWaitingUsers,
  approveAllWaitingUsers,
};
