import { RenderVideoStore } from '@/store/endpointMedia/RenderVideoStore';

export type LocalVideoPositionString = 'first' | 'last';

export type LocalVideoPosition = number | LocalVideoPositionString;

export const fixLocalVideo = (endpoints: RenderVideoStore[], overflowEndpoints: string[]): void => {
  const localVideoIndex = overflowEndpoints.indexOf('local');

  if (localVideoIndex === -1) return;
  const lastElement = endpoints[endpoints.length - 1];
  const lastVisibleElementId = lastElement.stream.id;
  lastElement.stream.id = 'local';
  overflowEndpoints[localVideoIndex] = lastVisibleElementId;
};
