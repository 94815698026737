function debounce<F extends (...params: any[]) => void>(fn: F, delay: number) {
  let timeoutID: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => fn.apply(this, args), delay);
  } as F;
}

/**
 * @description debounce async function fn with delay; function will be run at first call;
 * calls within delay period will be ignored, but there will be same Promise<Result> as
 * the first call
 *
 * @param fn - async function that should be debounced
 * @param delay - delay to call fn one more time
 * @example ```javascript
 *  const debounced = asyncDebounce(someAsyncFunc, 1000); // someAsyncFunc resolves in 1ms with
 *  ...
 *  const call1 = await debounced();
 *  const call2 = await debounced(); // call2 === call1
 *  await delay(1000);
 *  const call3 = await debounced(); // call3 !== call2
 * ```
 */
const asyncDebounce = <R = unknown>(
  fn: (...params: any[]) => Promise<R>,
  delay: number
): ((...args: unknown[]) => Promise<R>) => {
  let promise: ReturnType<typeof fn> | undefined;
  return (...args: any[]): Promise<R> => {
    if (promise) return promise;

    promise = new Promise((resolve) => {
      const result = fn(args);
      resolve(result);
    });

    setTimeout(() => (promise = undefined), delay);
    return promise;
  };
};

export { debounce, asyncDebounce };
