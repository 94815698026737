import { createEvent, createStore } from 'effector';

type DrawerSection =
  | 'chat'
  | 'settings'
  | 'generalSettings'
  | 'changeLayout'
  | 'contactList'
  | 'userSettings'
  | 'chooseDevices'
  | 'userInTheMeeting';

interface DrawerStore {
  opened: boolean;
  section: DrawerSection;
  options?: Record<string, any>;
}

const $drawer = createStore<DrawerStore>({ opened: false, section: 'chat' });

const openDrawer = createEvent<{
  section: DrawerSection | undefined;
  options?: Record<string, any>;
}>();
const closeDrawer = createEvent<void>();
const toggleDrawer = createEvent<DrawerSection | undefined>();

$drawer.watch((value) => {
  console.log('$drawer', 'watch', value);
});

$drawer
  .on(openDrawer, (state, { section, options }) => ({
    opened: true,
    section: section || state.section,
    options: options ?? state.options,
  }))
  .on(closeDrawer, (state) => ({ ...state, opened: false }))
  .on(toggleDrawer, (state, section) => {
    if (!state.opened) {
      return { opened: true, section: section || state.section };
    }
    return { opened: false, section: state.section };
  });

export { DrawerSection, DrawerStore, $drawer, openDrawer, closeDrawer, toggleDrawer };
