
  import { defineComponent, onMounted } from 'vue';
  import { $drawer, openDrawer, closeDrawer } from '@/store/drawer';
  import { detectVendor } from '@/helpers/vendor';
  import { useStore } from 'effector-vue/composition';
  import { myLogger } from '@/helpers/initLogger';
  import appConfig from '@/config';
  import '@/store/loggers/init';
  // import '@/store/statistics/init';
  import '@/store/auth/init';
  import '@/store/users/init';
  import { detectPlatform } from '@/helpers/platform';

  export default defineComponent({
    setup() {
      const drawerStore = useStore($drawer);
      const onKeyDown = (ev: KeyboardEvent) => {
        if (ev.altKey && ev.code === 'KeyL') {
          if (!drawerStore.value.opened) {
            openDrawer({ section: 'changeLayout' });
          } else {
            closeDrawer();
          }

          ev.preventDefault();
          ev.cancelBubble = true;
        }
      };

      document.addEventListener('keydown', onKeyDown);
      if (appConfig.nodeEnv !== 'development') {
        const logger = myLogger(); // init Logger with bind console.*
        logger.muted = false;
        // write to Logger all users clicks
        window.addEventListener('click', (e) => {
          const target = e.target as HTMLElement;
          const userClickOptions = {
            className: target.getAttribute('class'),
            tagName: target.tagName,
            text: target.innerText,
          };
          console.log('[USER CLICK]', JSON.stringify(userClickOptions));
        });
      }
      onMounted(() => {
        detectVendor();
        detectPlatform();
      });
    },
  });
