import { delay } from '@/helpers/delay';
import { stringifyError } from '@/helpers/stringifyError';
import { SDK, auth } from '@voximplant/websdk';
import { getDateTimeFormat } from '@/helpers/getDateTimeFormat';

const printLoginResult = (result: unknown) =>
  console.log(`authSDK: Successful login ${JSON.stringify(result)}`);

export const retrySdkLogin = async (
  authMethod: InstanceType<typeof auth['OtpAuth']>
): Promise<void> => {
  try {
    await delay(1000);
    const result = await SDK.login(authMethod);
    printLoginResult(result);
  } catch (err) {
    console.error(
      `authSDK: Unsuccessful attempt to login ${stringifyError(
        err
      )}. Time is ${getDateTimeFormat()}. Trying again...`
    );
    await delay(2000);
    const result = await SDK.login(authMethod);
    printLoginResult(result);
  }
};
