import { attach, createEffect, createEvent, createStore } from 'effector';
import { ChatStore } from '@/store/chat/ChatStore';
import { Conversation } from '@/store/chat/Conversation';
import { Message } from '@/store/chat/Message';
import { authStore } from '@/store/auth';
import { updateMe } from '@/store/users';
import { refreshableApiDomain } from '@/store/domains';

interface UserAuth {
  avatar?: string;
  authId?: number;
  username?: string;
}

interface ChatUser {
  chatUser: any;
  authUser?: UserAuth;
  inCall?: boolean;
  endpointId?: string;
}

interface ChatUsersStore {
  me?: ChatUser;
  users: ChatUser[];
}

const $chatUsers = createStore<ChatUsersStore>({
  me: void 0,
  users: [],
});
const $isChatLoading = createStore<boolean>(false);
const $chatContent = createStore<ChatStore>({});
const plusNewMessage = createEvent();
const resetMessageCounter = createEvent();
const $newMessagesCount = createStore<number>(0)
  .on(plusNewMessage, (state) => {
    let count = state;
    console.log('$newMessagesCount', 'plusNewMessage', ++count);
    return ++state;
  })
  .on(resetMessageCounter, () => {
    console.log('$newMessagesCount', 'resetMessageCounter');
    return 0;
  });

const createChat = createEffect<void, Conversation, void>();
const joinChat = createEffect<string, Conversation, void>();
const fetchInitialData = createEffect<Conversation, { messages: Message[] }, void>();
const updateChatUsers = refreshableApiDomain.createEffect<string[], any, unknown>();
const subscribeChat = createEvent<Conversation>();
const clearChat = createEvent();
const toggleChatLoading = createEvent<boolean>();
const updateChatContent = createEvent<any>(); // событие на обновление сообщения или реакции в чате
const sendMessageToAll = createEvent<{ text: string; customData?: any[] }>();
const updateLocalChatUser = attach<void, typeof authStore, typeof updateMe>({
  effect: updateMe,
  source: authStore,
  mapParams: (_, { id, picture }) => ({ id: id as number, picture: picture as string }),
});

export {
  ChatUser,
  $chatContent,
  $newMessagesCount,
  plusNewMessage,
  resetMessageCounter,
  updateChatContent,
  updateLocalChatUser,
  createChat,
  joinChat,
  fetchInitialData,
  subscribeChat,
  clearChat,
  sendMessageToAll,
  $chatUsers,
  updateChatUsers,
  $isChatLoading,
  toggleChatLoading,
};
